import { Typography,Button } from '@mui/material';
import * as React from 'react'
import { useState } from 'react'
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Divider from '@mui/material/Divider';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import { Link } from "react-router-dom";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DryCleaningIcon from '@mui/icons-material/DryCleaning';
import { DataGrid,  GridToolbar} from '@mui/x-data-grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate} from "react-router-dom";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Burbujas from './Burbujas';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Map, Marker } from "pigeon-maps"
import { stamenToner } from 'pigeon-maps/providers'
import { stamenTerrain } from 'pigeon-maps/providers';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import StoreIcon from '@mui/icons-material/Store';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarIcon from '@mui/icons-material/Star';
import LocalLaundryServiceIcon from '@mui/icons-material/LocalLaundryService';
import IronIcon from '@mui/icons-material/Iron';
import { Helmet } from 'react-helmet';

export default function Home() {

    //const urlFetch2='https://fastapirl.vercel.app/productos';
    //console.log('urlfetch,',urlFetch,urlFetch2)
    let { params } = useParams();
    const { t, i18n } = useTranslation();
    const [center, setCenter] = useState([37.371590, -5.980970])
    const [zoom, setZoom] = useState(16)
    const [productosDB,setProductosDB]=useState([]);
    const [options,setOptions]=useState([]);
    const [allProducts,setAllProducts]=useState({});

    const [precioProductoSeleccionado,setPrecioProductoSeleccionado]=useState('');

    //https://lvamzjuhoevnaxiisevl.supabase.co/storage/v1/object/public/home/alfombras2.png?t=2023-10-22T08%3A24%3A56.537Z
    const urlImages='https://lvamzjuhoevnaxiisevl.supabase.co/storage/v1/object/public/home/'  
    const urlFetch=process.env.REACT_APP_API_URL+'productos';
    console.log('urlFetch',urlFetch);
    const itemData = [
      {
        img: urlImages+'fiesta.png',
        title: 'trajes de fiesta',
      },
      {
        img: urlImages+'/boda2.png',
        title: 'novia/o',
      },
      {
        img: urlImages+'cama.png',
        title: 'ropa de cama',
      },
      {
        img: urlImages+'flamenca.png',
        title: 'flamenca',
      },
      {
        img: urlImages+'alfombras2.png',
        title: 'alfombras',
      },
      {
        img: urlImages+'engrasado.png',
        title: 'engrasado',
      },
      {
        img: urlImages+'cortinas3.png',
        title: 'cortinas',
      },
      {
        img: urlImages+'nazareno.png',
        title: 'nazareno',
      }
     
    ];

    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('lg'));
    let tablet = useMediaQuery(theme.breakpoints.down ('lg'));
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    if (mobile) {tablet=false;}
    //const tablet=false;
    
    /*
    const options = top100Films.map((option) => {
      const firstLetter = option.title[0].toUpperCase();
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
        ...option,
      };
    });
*/
React.useEffect(()=> {
  fetchProductos();      
}
, [])

  React.useEffect(()=> {
    if (productosDB.length!==0)
    {  
      //console.log("productos",productosDB);
      const nombres=productosDB.filter(productoDB=>productoDB.servicio===false).map((producto)=>{
        //console.log("prod",producto);
        return {'title':producto.nombre,'firstLetter':producto.nombre.toUpperCase().charAt(0),'id':producto.id}
        }
      );
      const productsDict={};
      productosDB.map((producto)=>{
        //console.log("prod",producto);
        productsDict[producto.id]={'nombre':producto.nombre,'precio':producto.precio,'servicio':producto.servicio};
        }
      );
      //console.log("nombres",nombres);
      //console.log("productsDict",productsDict);
      setOptions(nombres);
      setAllProducts(productsDict);  
    }
    }
  , [productosDB])

  function fetchProductos() {
    fetch(urlFetch)
    .then(response => {return response.json()})
    .then(data => {
        setProductosDB(data);
    })
    .catch(error=> {console.log(error)})
  }

  function handleProductoSeleccionado(e,value) {
    //console.log(value);
    if (value)
    {
      if (productosDB.filter(producto=>producto.nombre===value.title).length>0) {
        const precioProducto=productosDB.filter(producto=>producto.nombre===value.title)[0].precio;
        //console.log(value,precioProducto);
        setPrecioProductoSeleccionado(precioProducto);
      }
    }

  }
    const navigate = useNavigate()

    function goToPedido() {
      navigate('/pedidos')
    }

    function imageClick(event) {
        console.log("e",event.target);
    }

        const LightTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
      },
    }));
    return(
        < >
        <Helmet>
        <title>Tintoreria Sonia Sevilla</title>
        <meta
      name="description"
      content="Tintoreria Sonia. Tintoreria tradicional de Sevilla, en el barrio del Porvenir."
        />
        </Helmet>
        <Paper sx={{p:1,backgroundColor:"#ffffff"}} elevation={4} >
        {
        tablet?
        <>
        <Grid container sx={{m:0}} spacing={0} alignItems="center" justifyContent="center">
          
        <List
              sx={{ width: '100%',p:0,mt:1,mb:2,borderTop:3,borderBottom:3,borderColor:'#EBE2D6'}}
              aria-label="contacts"
                >
              <ListItem  sx={{ width: '100%',p:0,m:0 }}>
                <ListItemIcon sx={{p:0,m:0}}>
                    <DryCleaningIcon sx={{p:0,m:0,ml:0,color:"#EBE2D6"}}/>
                  </ListItemIcon>
                <Typography variant="body2" color="inherit" 
                    sx={{fontSize:'80%',fontWeight:"300",ml:-2,mr:1,pt:0,pb:0,mt:1,mb:1}}>
                      La Tintorería de Sonia es una  <span style={{fontWeight:"500"}}> empresa familiar </span>, situada en el sevillano barrio del Porvenir <span style={{fontWeight:"500"}}>desde 1977</span>. 
                      Nos caracteriza ser una <span style={{fontWeight:"500"}}> tintorería tradicional </span> que cuida cada prenda de forma <span style={{fontWeight:"500"}}> personalizada </span>.
                    </Typography>
              </ListItem>
              </List>

          <ImageList  cols={4}>
            {itemData.map((item) => (
              <ImageListItem key={item.img}>
                <img
                  srcSet={`${item.img}?w=188&fit=crop&auto=format&dpr=2 2x`}
                  src={`${item.img}?w=188&fit=crop&auto=format`}
                  alt={item.title}
                  loading="lazy"
                />
              <ImageListItemBar   
              position='below'
              title={item.title}
              /*
              actionIcon={
                <LightTooltip 
                
                title= {<React.Fragment>
                <Typography variant="overline" color="inherit">{item.title}</Typography>
              </React.Fragment>}
                
                >
                <IconButton
                  sx={{ color:'#B2B2B2', fontWeight:'100' }}
                  aria-label={`info about ${item.title}`}
                  size="small"
                  onClick={imageClick}
                >
                  <Avatar variant="square" sx={{ bgcolor:'white',color:'#ED6C02',border:'0px solid #ED6C02',fontWeight:"300", width: 32, height: 20,fontSize:'60%' }} aria-label="recipe" >+info</Avatar>
                
                  
                </IconButton>
                </LightTooltip>
              }
            */
            />
              </ImageListItem>
            ))}
          </ImageList>
          <Stack  sx={{width:'100%',borderBottom:3,borderTop:3,borderColor:'#EBE2D6',pb:1}} alignItems="center" justifyContent="center">
          <Typography  sx={{marginLeft:1,fontSize:'95%',paddingRight:1,borderRight:0,borderRightColor:'#503543'}} variant="overline">
          <span style={{fontWeight:"500"}}>Pedido</span>
          </Typography>
          <Button color="warning" variant="outlined" sx={{textAlign:'center',mb:1,mt:1,width:'90%'}} onClick={goToPedido}>Realizar online</Button>
          </Stack>
          <Stack sx={{width:'100%',borderColor:'#EBE2D6',pb:1,pl:0}} alignItems="center" justifyContent="center" >
              <Typography  sx={{mb:-2,marginLeft:1,fontSize:'95%',paddingRight:1,borderRight:0,borderRightColor:'#503543'}} variant="overline">
              <span style={{fontWeight:"500",mb:0}}>Información</span> 
              </Typography>
              <Box sx={{mt:0,p:0}}>
              <CardHeader 
              sx={{m:-1,mt:0}}
              subheaderTypographyProps={{fontSize:'80%',ml:0,color:'#686868'}} avatar={<Avatar sx={{ bgcolor:'#fff',color:'#EE7918' }} aria-label="recipe"><StoreIcon/></Avatar>}
              subheader="C/ Felipe II, 24. Tel:954233312"
              //subheader="616000000 - 616000001"
                          />
             <CardHeader 
             sx={{m:-1,mt:-4}}
             subheaderTypographyProps={{fontSize:'80%',ml:0,color:'#686868'}} avatar={<Avatar sx={{ bgcolor:'#fff',color:'purple' }} aria-label="recipe"><StoreIcon/></Avatar>}
             subheader="C/ Cardenal Bueno Monreal, 20. Tel:954628401"
                          //subheader="616000000 - 616000001"
                          />
              <CardHeader 
                  sx={{m:-1,mt:-4}}
                  subheaderTypographyProps={{fontSize:'80%',ml:0,color:'#686868'}}
                  avatar={<Avatar variant="square" sx={{ bgcolor:'#fff',color:'#503543' }} aria-label="recipe"><AccessTimeIcon/></Avatar>}
                  subheader="Lun-Vie. 9h-14h y 17h-19:30h"
                              //subheader="616000000 - 616000001"
              />
              <CardHeader 
              sx={{m:-1,mt:-4}}
              subheaderTypographyProps={{fontSize:'80%',ml:0,color:'#686868'}}
              avatar={<Avatar variant="square" sx={{ bgcolor:'#fff',color:'#503543' }} aria-label="recipe"><WhatsAppIcon/></Avatar>}
              subheader="685892109"
              //subheader="616000000 - 616000001"
              />
              <CardHeader 
             sx={{m:-1,mt:-4}}
             subheaderTypographyProps={{fontSize:'80%',ml:0,color:'#686868'}} avatar={<Avatar sx={{ bgcolor:'#fff',color:'#503543' }} aria-label="recipe"><MailOutlineIcon/></Avatar>}
             subheader="admin@tintoreriasonia.com"
                          //subheader="616000000 - 616000001"
                          />
            
            </Box>
            </Stack>
      </Grid>
        </>
        :mobile?
        <Grid container sx={{m:0}} spacing={0} alignItems="center" justifyContent="center">
              <List
              sx={{ width: '100%',p:0,mt:1,mb:2,borderTop:3,borderBottom:3,borderColor:'#EBE2D6'}}
              aria-label="contacts"
                >
              <ListItem  sx={{ width: '100%',p:0,m:0 }}>
                <ListItemIcon sx={{p:0,m:0}}>
                    <DryCleaningIcon sx={{p:0,m:0,ml:0,color:"#EBE2D6"}}/>
                  </ListItemIcon>
                <Typography variant="body2" color="inherit" 
                    sx={{fontSize:'80%',fontWeight:"300",ml:-2,mr:1,pt:0,pb:0,mt:1,mb:1}}>
                      Cuidado <span style={{fontWeight:"500"}}>personalizado</span> de prendas y <span style={{fontWeight:"500"}}> atención</span> al <span style={{fontWeight:"500"}}>cliente</span>. En Sevilla, en el barrio del Porvenir, desde 1977.
                    </Typography>
              </ListItem>
              </List>
              <ImageList  cols={2}>
                {itemData.map((item) => (
                  <ImageListItem key={item.img}>
                    <img
                      srcSet={`${item.img}?w=188&fit=crop&auto=format&dpr=2 2x`}
                      src={`${item.img}?w=188&fit=crop&auto=format`}
                      alt={item.title}
                      loading="lazy"
                    />
                  <ImageListItemBar   
                  position='below'
                  title={item.title}
                  
                  /*
                  actionIcon={
                    
                    <IconButton
                      sx={{ color:'#B2B2B2', fontWeight:'100' }}
                      aria-label={`info about ${item.title}`}
                      size="small"
                      onClick={imageClick}
                    >
                      <Avatar variant="square" sx={{ bgcolor:'white',color:'#ED6C02',border:'0px solid #ED6C02',fontWeight:"300", width: 32, height: 20,fontSize:'60%' }} aria-label="recipe" >+info</Avatar>
                    
                      
                    </IconButton>
                  }
                  */
                />
                  </ImageListItem>
                ))}
              </ImageList>
            <Stack  sx={{width:'100%',borderBottom:3,borderTop:3,borderColor:'#EBE2D6',pb:1}} alignItems="center" justifyContent="center">
            <Typography  sx={{marginLeft:1,fontSize:'95%',paddingRight:1,borderRight:0,borderRightColor:'#503543'}} variant="overline">
            <span style={{fontWeight:"500"}}>Pedido</span>
            </Typography>
            <Button color="warning" variant="outlined" sx={{textAlign:'center',mb:1,mt:1,width:'90%'}} onClick={goToPedido}>Realizar online</Button>
            </Stack>
            <Stack sx={{width:'100%',borderColor:'#EBE2D6',pb:1,pl:0}} alignItems="center" justifyContent="center" >
              <Typography  sx={{mb:-2,marginLeft:1,fontSize:'95%',paddingRight:1,borderRight:0,borderRightColor:'#503543'}} variant="overline">
              <span style={{fontWeight:"500",mb:0}}>Información</span> 
              </Typography>
              <Box sx={{mt:0,p:0}}>
              <CardHeader 
              sx={{m:-1,mt:0}}
              subheaderTypographyProps={{fontSize:'80%',ml:0,color:'#686868'}} avatar={<Avatar sx={{ bgcolor:'#fff',color:'#EE7918' }} aria-label="recipe"><StoreIcon/></Avatar>}
              subheader="C/ Felipe II, 24. Tel:954233312"
              //subheader="616000000 - 616000001"
                          />
             <CardHeader 
             sx={{m:-1,mt:-4}}
             subheaderTypographyProps={{fontSize:'80%',ml:0,color:'#686868'}} avatar={<Avatar sx={{ bgcolor:'#fff',color:'purple' }} aria-label="recipe"><StoreIcon/></Avatar>}
             subheader="C/ Cardenal Bueno Monreal, 20. Tel:954628401"
                          //subheader="616000000 - 616000001"
                          />
              <CardHeader 
                  sx={{m:-1,mt:-4}}
                  subheaderTypographyProps={{fontSize:'80%',ml:0,color:'#686868'}}
                  avatar={<Avatar variant="square" sx={{ bgcolor:'#fff',color:'#503543' }} aria-label="recipe"><AccessTimeIcon/></Avatar>}
                  subheader="Lun-Vie. 9h-14h y 17h-19:30h"
                              //subheader="616000000 - 616000001"
              />
              <CardHeader 
              sx={{m:-1,mt:-4}}
              subheaderTypographyProps={{fontSize:'80%',ml:0,color:'#686868'}}
              avatar={<Avatar variant="square" sx={{ bgcolor:'#fff',color:'#503543' }} aria-label="recipe"><WhatsAppIcon/></Avatar>}
              subheader="685892109"
              //subheader="616000000 - 616000001"
              />
              <CardHeader 
             sx={{m:-1,mt:-4}}
             subheaderTypographyProps={{fontSize:'80%',ml:0,color:'#686868'}} avatar={<Avatar sx={{ bgcolor:'#fff',color:'#503543' }} aria-label="recipe"><MailOutlineIcon/></Avatar>}
             subheader="admin@tintoreriasonia.com"
                          //subheader="616000000 - 616000001"
                          />
            
            </Box>
            </Stack>
        </Grid>
        :desktop?
        <Grid sx={{width:'100%'}}  justifyContent="center" container spacing={0}  >  
        <Stack direction='row' spacing={2} justifyContent="center" sx={{mt:2}}>   
            <Card sx={{width:'65%',m:2,pl:2,pr:2,pb:2,pt:0}}>
             <Stack justifyContent="center" direction="row">     
            <Typography  color="success" sx={{marginLeft:0,fontSize:'160%'}} variant="overline"><span style={{fontWeight:"200"}}>La </span><span style={{fontWeight:"500"}}>Tintorería</span><span style={{fontWeight:"200"}}> de </span><span style={{fontWeight:"500"}}>Sonia</span>
            </Typography>
            </Stack>
            <List
              sx={{ width: '100%',p:0,mt:-1,mb:1}}
              aria-label="contacts"
                >
              <ListItem  sx={{ width:'100%',p:0,m:0,mt:0,borderTop:0,borderBottom:0,borderColor:'#EBE2D6' }}>
             
                <ListItemIcon  sx={{p:0,m:0}}>
                    <DryCleaningIcon  sx={{p:0,m:0,ml:0,mt:-2,color:"#EBE2D6",fontSize:'36px'}}/>
                  </ListItemIcon>
                  <Typography sx={{p:1,mt:-0.5,mb:0.5,fontWeight:"300"}} variant="body2" fontSize="95%">
            La Tintorería de Sonia es una <span style={{fontWeight:"500"}}>empresa familiar</span>, situada en el sevillano barrio del Porvenir, desde el año 1977. Nos caracteriza ser una <span style={{fontWeight:"500"}}>tintorería tradicional</span> que <span style={{fontWeight:"500"}}>cuida</span> cada prenda de forma <span style={{fontWeight:"500"}}>personalizada</span>. 
            </Typography>    
              </ListItem>
              </List>
            <ImageList  cols={4}>
            {itemData.map((item) => (
              <ImageListItem key={item.img}>
                <img
                  srcSet={`${item.img}?w=40&fit=crop&auto=format&dpr=2 2x`}
                  src={`${item.img}?w=40&fit=crop&auto=format`}
                  alt={item.title}
                  loading="lazy"
                />
              <ImageListItemBar   
              position='below'
              title={item.title}
              /*
              actionIcon={
                <LightTooltip 
                
                title= {<React.Fragment>
                <Typography variant="overline" color="inherit">{item.title}</Typography>
              </React.Fragment>}
                
                >
                <IconButton
                  sx={{ color:'#B2B2B2', fontWeight:'100' }}
                  aria-label={`info about ${item.title}`}
                  size="small"
                  onClick={imageClick}
                >
                  <Avatar variant="square" sx={{ bgcolor:'white',color:'#ED6C02',border:'0px solid #ED6C02',fontWeight:"300", width: 32, height: 20,fontSize:'60%' }} aria-label="recipe" >+info</Avatar>
                
                  
                </IconButton>
                </LightTooltip>
              }
              */
            />
              </ImageListItem>
            ))}
          </ImageList>
        
            </Card>
          <Card sx={{width:'33%',m:2,pt:0,pb:0}}>
          <Stack justifyContent="center" direction="row">     
          <Typography  color="success" sx={{marginLeft:0,fontSize:'160%'}} variant="overline"><span style={{fontWeight:"200"}}></span><span style={{fontWeight:"500"}}>Tiendas</span></Typography>
           </Stack>   
              <CardHeader sx={{mt:-2}}avatar={<div style={{"cursor": "pointer"}}><Avatar onClick={()=>{setZoom(16);setCenter([37.371590, -5.980970]);}} sx={{width:32,height:32,bgcolor:'white',color:'#ED6C02',border:'0px solid #ED6C02',fontWeight:"400"}} aria-label="recipe"><StoreIcon/></Avatar></div>}
                      title="C/ Felipe II, 24"
                      subheader="Tel: 954233312"
                      //subheader="616000000 - 616000001"
                    />
                    
                  <CardHeader sx={{mt:-2}} avatar={<div style={{"cursor": "pointer"}}><Avatar onClick={()=>{setZoom(16);setCenter([37.369440, -5.978420])}}sx={{width:32,height:32,bgcolor:'white',color:'purple',border:'0px solid purple',fontWeight:"400"}} aria-label="recipe"><StoreIcon/></Avatar></div>}
                          title="C/ Cardenal Bueno Monreal, 20"
                          subheader="Tel: 954628401"
                          //subheader="616000000 - 616000001"
                        />
                      <CardContent sx={{mt:-2,mb:-2}}>
                          <Map 
                          height={80} defaultCenter={[37.371590, -5.980970]} defaultZoom={zoom} zoom={zoom} attribution={false} attributionPrefix={false}
                          center={center}
                          >
                          <Marker color="#ED6C02" width={30} anchor={[37.371590, -5.980970]} hover={false}/>
                          <Marker color="purple" width={30} anchor={[37.369440, -5.978420]} hover={false}/>

                        </Map>
                      </CardContent>
                    <Divider light />
      
                    <CardHeader sx={{mt:-1}} avatar={<Avatar variant="square" sx={{ bgcolor:'#fff',color:'#B2B2B2' }} aria-label="recipe"><AccessTimeIcon/></Avatar>}
                          title="Horario"
                          subheader="Lun-Vie. 9h-14h y 17h-19:30h"
                          //subheader="616000000 - 616000001"
                        />
                    <CardHeader sx={{mt:-3}} avatar={<Avatar variant="square" sx={{ bgcolor:'#fff',color:'#B2B2B2' }} aria-label="recipe"><WhatsAppIcon/></Avatar>}
                    title="Whatsapp"
                    subheader="685892109"
                    //subheader="616000000 - 616000001"
                  />
                  <CardHeader sx={{mt:-3}} avatar={<Avatar variant="square" sx={{ bgcolor:'#fff',color:'#B2B2B2' }} aria-label="recipe"><MailOutlineIcon/></Avatar>}
                    title="Email"
                    subheader="admin@tintoreriasonia.com"
                    //subheader="616000000 - 616000001"
                  />
                        <CardHeader sx={{mt:-3}} avatar={<Avatar variant="square" sx={{ bgcolor:'#fff',color:'#B2B2B2' }} aria-label="recipe"><DryCleaningIcon/></Avatar>}
                    title="Servicios"
                    subheader="Urgente, Recogida a domicilio"
                    //subheader="616000000 - 616000001"
                  />
          </Card>
        </Stack>
        <Stack direction='row' spacing={2} justifyContent="center" sx={{mt:2}}>
            <Card sx={{pl:2,pr:2,pb:2,pt:0}}>
              <Typography  color="success" sx={{marginLeft:2,fontSize:'160%'}} variant="overline"><span style={{fontWeight:"500"}}>Buscador </span><span style={{fontWeight:"200"}}>de</span><span style={{fontWeight:"500"}}> Precios </span></Typography>
              <Stack direction="row" sx={{mb:2,ml:2}} spacing={4} alignItems="center">
              <Autocomplete
                          id="grouped-demo"
                          options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                          groupBy={(option) => option.firstLetter}
                          getOptionLabel={(option) => option.title}
                          sx={{ width: 300}}
                          onChange={handleProductoSeleccionado}
                          renderInput={(params) => <
                            TextField {...params} sx={{mt:-2}} label="Prenda" variant="standard"
                            //inputRef={productoSeleccionado}
                            disabled={productosDB.length===0}
                            />
                          }
                          />
                      <TextField InputLabelProps={{ shrink: true }} 
                      value={precioProductoSeleccionado+'€'} 
                      sx={{ width: 150, mr:2,color:"orange"}} variant="outlined" label='precio'
                      disabled/>
                  </Stack>
            </Card>
            <Card sx={{m:2,pl:2,pr:2,pb:2,pt:0}}>
              <Typography  color="success" sx={{marginLeft:2,fontSize:'160%'}} variant="overline"><span style={{fontWeight:"500"}}>Pedido </span><span style={{fontWeight:"500"}}>Online</span> 
              </Typography>
              <Button color="warning" variant="outlined" sx={{textAlign:'center',width:'100%',mb:1,mt:1}} onClick={goToPedido}>Comenzar</Button>
            </Card>
         </Stack>   
        </Grid>
        :
        <>
        None
        </>
      }
        </Paper>
        </>
    );

}