import { Typography } from '@mui/material';
import * as React from 'react'
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { DataGrid,  GridToolbar} from '@mui/x-data-grid';
import { Link } from "react-router-dom";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import EuroIcon from '@mui/icons-material/Euro';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import axios from "axios";
import Alert from '@mui/material/Alert';
import currency from 'currency.js';

export default function GestionPrecios(props) {
    
    const {user,setUser,token}=props;
    const precioPrendaNueva=React.useRef();
    const nombrePrendaNueva=React.useRef('');
    const servicioPrendaNueva=React.useRef(false);
    const precioPrendaActualizada=React.useRef('');
    const nombrePrendaActualizada=React.useRef('');
    const nombrePrendaBorrada=React.useRef('');
    const [productosDB,setProductosDB]=React.useState([]);
    const [options,setOptions]=React.useState([]);
    const [allProducts,setAllProducts]=React.useState({});
    const [rows,setRows]=React.useState([]);
    const [estado,setEstado]=React.useState('');

    let  top100Films = [ 
        {title:"Camisa", categoria:'ropa'},
        {title:"Camisón", categoria:'ropa'},
        {title:"Abrigo", categoria:'ropa'},
        {title:"Pantalón", categoria:'ropa'},
        {title:"Pantalón corto", categoria:'ropa'},
    
      ];

/*
      const options = top100Films.map((option) => {
        const firstLetter = option.title[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          ...option,
        };
      });
      */

      React.useEffect(()=> {
        fetchProductos();      
    }
    , [])
  
    React.useEffect(()=> {
      if (productosDB.length!==0)
      {  
        //console.log("productos",productosDB);
        const nombres=productosDB.map((producto)=>{
          //console.log("prod",producto);
          return {'title':producto.nombre,'firstLetter':producto.nombre.toUpperCase().charAt(0),'id':producto.id}
          }
        );
        const productsDict=productosDB.map((producto,num)=>{
          console.log("prod",producto.nombre,producto.precio,producto.id,num);
          return {'prenda':producto.nombre,'precio':producto.precio,'id':producto.id};
          }
        );
        //console.log("nombres",nombres);
        //console.log("productsDict",productsDict);
        //{id:1,prenda:'Pantalón',precio:10},
        setOptions(nombres);
        //setAllProducts(productsDict);
        setRows(productsDict);  
      }
      }
    , [productosDB])


      function fetchProductos() {
        const urlFetch=process.env.REACT_APP_API_URL+"productos";
        fetch(urlFetch)
        .then(response => {return response.json()})
        .then(data => {
            setProductosDB(data);
            //setEstado('');
        })
        .catch(error=> {console.log(error)})
      }
  
      const columns = [
        {
        field: 'id',
            headerName: 'Código',
            //width: '15%',
            flex:1,
            editable: false,
            //sortable:true, 
            hidden:true    
          },
          {
            field: 'prenda',
            headerName: 'Prenda/Servicio',
            type: 'text',
            //width: 100,
            editable: false,
            sortable:true,     
            flex:2,
          },
        {
          field: 'precio',
          headerName: 'Precio',
          //width: 150,
          flex:1,
          editable: false,
          sortable:true,
          valueGetter: ({ row }) => {
            return row.precio+'€';
          },     
        },
      ];
/*
      const rows = [
        { id: 1, prenda:'Pantalón',precio:10},
        { id: 2, prenda:'Pantalón',precio:10},
        { id: 3, prenda:'Pantalón',precio:10},
        { id: 4, prenda:'Pantalón',precio:10},
        { id: 5, prenda:'Pantalón',precio:10},
        { id: 6, prenda:'Pantalón',precio:10},
        { id: 7, prenda:'Pantalón',precio:10},
      ];
*/
      const getCellClassName = ({ row, field }) => {
        //console.log(field,row)
        if (row.id%2=== 0) { 
            return 'par';
          }
        else  { 
            return 'impar';
          }  
      };

      function nuevaPrenda() {
        
        let prenda=nombrePrendaNueva.current.value;
        let precio=precioPrendaNueva.current.value.replace(',','.');
        if (precio.includes('.')) {
          const with2Decimals = precio.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
          precio=with2Decimals;
        }
        //const servicio=servicioPrendaNueva.current.checked;
        let categoria='ropa';
        //if (servicio) {categoria='servicio'}
        if (prenda!=='' && precio!=='')
        console.log("nueva prenda",prenda,precio);
        {
          const dataProducto={
            "nombre":prenda,
            "categoria":categoria,
            "precio":precio,
            "servicio":false
          }

          if (token!=='' && token)
          {
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;  
          console.log('tokrn',token);
          const urlDataProducto=process.env.REACT_APP_API_URL+`admin/productos/crear_producto`;
          axios
          .post(urlDataProducto,
          dataProducto,
          {
            headers: {
              'Authorization': `Bearer ${token}`
          },
          })
        .then((response) => {
          console.log("Producto nuevo", response.data);
          setEstado('nuevo');
          fetchProductos()
        //setEstado('Validado');
      //setFinished(true);
     })
     .catch((error) => {
      console.error("Producto nuevo error");
      setEstado('nuevoError');
     });
    }          
        }
      }

      function actualizarPrenda() {
        let prenda=nombrePrendaActualizada.current.value;
        let precio=precioPrendaActualizada.current.value.replace(',','.');
        if (precio.includes('.')) {
          const with2Decimals = precio.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
          precio=with2Decimals;
        }
        //const servicio=servicioPrendaNueva.current.checked;
        //let categoria='ropa';
        //if (servicio) {categoria='servicio'}
        //console.log('orenda',prenda,productosDB.filter(producto=>producto.nombre===prenda));
        //if (false)
        if (prenda!=='' && precio!=='')
        {
          let productoId=-1;
          if (productosDB.filter(producto=>producto.nombre===prenda).length>0)
          {productoId=productosDB.filter(producto=>producto.nombre===prenda)[0].id ;
          const servicioPrendaActualizada=productosDB.filter(producto=>producto.nombre===prenda)[0].servicio;
          const categoriaPrendaActualizada=productosDB.filter(producto=>producto.nombre===prenda)[0].categoria;
          console.log("prenda actualizada",productoId,prenda,precio,servicioPrendaActualizada,categoriaPrendaActualizada);
          const dataProducto={
            "nombre":prenda,
            "categoria":categoriaPrendaActualizada,
            "precio":precio,
            "servicio":servicioPrendaActualizada
          }
          if (token!=='' && token)
          {
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;  
          //console.log('userId',userId);
          const urlDataProducto=process.env.REACT_APP_API_URL+`admin/productos/actualizar_producto/${productoId}`;
          axios
          .put(urlDataProducto,
          dataProducto,
          {
            headers: {
              'Authorization': `Bearer ${token}`
          },
          })
        .then((response) => {
          console.log("Producto actualizado", response);
          setEstado('actualizado')
          fetchProductos()
        //setEstado('Validado');
      //setFinished(true);
     })
     .catch((error) => {
      console.error("Producto actualizado error");
      setEstado("actualizadoError");
     });
     
    }    
   
  }
  else {
    console.error("Producto actualizado error");
    setEstado("actualizadoError");
   }   
  }
      
  }

      function borrarPrenda() {

      let prenda=nombrePrendaBorrada.current.value;
      console.log('prenda borrada',prenda);
      //const servicio=servicioPrendaNueva.current.checked;
      //let categoria='ropa';
      //if (servicio) {categoria='servicio'}
      if (prenda!=='')
      {
        let productoId=-1;
        if (productosDB.filter(producto=>producto.nombre===prenda).length>0)
        {productoId=productosDB.filter(producto=>producto.nombre===prenda)[0].id };
        console.log("prenda borrada",productoId,prenda);
        /*
        const dataProducto={
          "nombre":prenda,
          "categoria":categoriaPrendaActualizada,
          "precio":precio,
          "servicio":servicioPrendaActualizada
        }
        */
        if (token!=='' && token)
        {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;  
        //console.log('userId',userId);
        const urlDataProducto=process.env.REACT_APP_API_URL+`admin/productos/borrar_producto/${productoId}`;
        axios
        .delete(urlDataProducto,
        {
          headers: {
            'Authorization': `Bearer ${token}`
        },
        })
      .then((response) => {
        console.log("Producto borrado", response);
        setEstado('borrado')
        fetchProductos()
    //setFinished(true);
   })
   .catch((error) => {
    console.error("Producto borrado error");
    setEstado("borradoError");
   });
   
  }    
    }
      
      }

    return(
        <>
        <Paper sx={{p:2,width:'100%'}} elevation={3} >
        <Typography variant="h6" sx={{mt:2,mb:2}}>Precios de prendas</Typography>
        <DataGrid sx={{ '& .impar': {backgroundColor:'#eeeeee'},'& .par': {backgroundColor:'#ffffff'}}}
        rows={rows}
        columns={columns}
        columnVisibilityModel={{
        //
        id:false  
        }}
        initialState={{
        pagination: {
         paginationModel: {
           pageSize: 10,
         },
        },
        }}
        slots={{
            toolbar: GridToolbar,
            }}
        //pageSizeOptions={[5]}
        //checkboxSelection=
        disableRowSelectionOnClick
        //hideFooter
        //density='compact'
        //disableColumnFilter
        //disableColumnMenu
        getCellClassName={getCellClassName}
        />
        {user.roles.includes("admin")?
        <>
        
        <Typography variant="overline" sx={{fontSize:'120%',mt:5,mb:2}}>Añadir Prenda</Typography>
        <Stack direction="row" sx={{mb:4}} spacing={4} alignItems="center">
                <TextField inputRef={nombrePrendaNueva} variant="outlined" label="Prenda"/>
                <TextField inputRef={precioPrendaNueva} variant="outlined" label="Precio"/> 
            <Button  variant="contained" size="small" onClick={nuevaPrenda} color='success'>Añadir</Button>
        </Stack>
        {estado==="nuevo"?
          <Alert severity="success">Producto creado</Alert>
          :estado==="nuevoError"?
          <Alert severity="error">Producto no creado. "puede existir"</Alert>    
          :
          <></>
        }
        <Typography variant="overline" sx={{fontSize:'120%',mt:5,mb:2}}>Cambiar precio</Typography>
        <Stack direction="row" sx={{mb:4}} spacing={4} alignItems="center">
                <Autocomplete
                    id="grouped-demo"
                    options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.title}
                    sx={{ width: 300,mt:1 }}
                    renderInput={(params) => <TextField inputRef={nombrePrendaActualizada}{...params} sx={{mt:-2}} label="Prenda" variant="standard"/>}
                    />

                <TextField inputRef={precioPrendaActualizada} variant="outlined" label="Precio"/> 
                    <Button  onClick={actualizarPrenda} variant="contained" size="small" color='warning'>Cambiar</Button>
              </Stack>
          {estado==="actualizado"?
          <Alert severity="warning">Producto actualizado</Alert>
          :estado==="actualizadoError"?
          <Alert severity="error">Error en actualización del producto</Alert>
          :<></>
          }
        <Typography variant="overline" sx={{fontSize:'120%',mt:5,mb:2}}>Borrar Prenda</Typography>
        <Stack direction="row" sx={{mb:4}} spacing={4} alignItems="center">
        <Autocomplete
                    id="grouped-demo"
                    options={options.filter(nombre=>nombre.title!=='Urgente' && nombre.title!=='Guardarropa').sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.title}
                    sx={{ width: 300,mt:1 }}
                    renderInput={(params) => <TextField inputRef={nombrePrendaBorrada} {...params} sx={{mt:-2}} label="Prenda" variant="standard"/>}
        />
        <Button  variant="contained" onClick={borrarPrenda} size="small" color='error'>Borrar</Button>
        </Stack>
        {estado==="borrado"?
        <Alert severity="error">Producto borrado</Alert>
        :estado==="borradoError"?
          <Alert severity="error">Error en borrado del producto</Alert>
        :<></>
        }
        </>
           :
           <>
           </>
           }
           </Paper>
           </>
     
    );
}
