import { Typography } from '@mui/material';
import * as React from 'react'
import Paper from '@mui/material/Paper';

export default function GestionUsuarios () {


    return(
        <>
        <Paper sx={{p:2,width:'100%'}} elevation={3} >
        <Typography>Gestión de usuarios</Typography>
        </Paper>
        </>
    );
}