import * as React from 'react'
import { BrowserRouter, Routes, Route,Navigate,useLocation} from "react-router-dom";
import Home from './componentesWeb/Home';
import Pedidos from './componentesWeb/Pedidos';
import Login from './componentesWeb/Login';
import PasswordReset from './componentesWeb/PasswordReset'
import ForgetPassword from './componentesWeb/ForgetPAssword'
import NavBarLogin from './componentesWeb/NavBarLogin';
import NavBarCliente from './componentesWeb/NavBarCliente';
import NavBarTrabajador from './componentesWeb/NavBarTrabajador';
import Container from '@mui/material/Container';
import GestionPedidos from './componentes/GestionPedidos';
import Cliente from './componentes/Cliente';
import DetallesPedido from './componentes/DetallesPedido';
import GestionPrecios from './componentes/GestionPrecios';
import GestionUsuarios from './componentes/GestionUsuarios';
import {AuthContext} from './App';
import Footer from './componentesWeb/Footer'

import jwt from 'jwt-decode'

export default function Router(props) {
    //const {user,setUser,token,setToken}=props;
    const {token,setToken,user,setUser} = React.useContext(AuthContext);
    //const [user,setUser]=React.useState(null);
   
 /*  
    React.useEffect(()=> {

      if (token)
          {
          const userId=jwt(token).id;
          const userRole=jwt(token).role || 'cliente';
          const userName=jwt(token).sub;
          const roles=[userRole];
          //console.log(jwt(token),userId,userRole,userName);
          setUser({name:userName,roles:roles,permissions:[]});
          }
        }
        , [token])
*/
    const ProtectedRoute = ({user,redirectPath = '/',children,}) => {
      if (user) {
        //console.log("user protected",user, user.roles);
      }
      if (!user) {
        return <Navigate to={redirectPath} replace />;
      }
      return children ? children : <Home/>;
    };




    return (
        <>
          <BrowserRouter>
          {!user?
          <NavBarLogin  user={user} setUser={setUser}/>
          :user.name==='cliente' || user.roles.includes('cliente')?
          <NavBarCliente  user={user} setUser={setUser}/>
          :user.name==='trabajador' || user.name==='admin' || user.roles.includes('admin')?
          <NavBarTrabajador  user={user} setUser={setUser}/>
          :<></>
          }
          <Container sx={{mt:1,pl:1,pr:1}}>
            <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/password_reset/:token_param" element={<PasswordReset token={token} setToken={setToken} user={user} setUser={setUser}/>} />
            <Route path="/forget_password" element={<ForgetPassword/>} />
            <Route path="/login" element={<Login token={token} setToken={setToken} user={user} setUser={setUser}/>} />
            <Route path="/cliente" element={<ProtectedRoute user={user} redirectPath="/login" isAllowed={!!user && user.roles.includes('cliente')}> <Cliente user={user} /> </ProtectedRoute>}/>
            <Route path="/pedidos" element={<Pedidos user={user} setUser={setUser}/>} />
            <Route path="/gestion_pedidos" element={<ProtectedRoute user={user} redirectPath="/" isAllowed={!!user && (user.roles.includes('admin') || user.roles.includes('trabajador'))}> <GestionPedidos token={token} setToken={setToken} user={user} setUser={setUser}/> </ProtectedRoute>}/>
            <Route path="/gestion_precios" element={<ProtectedRoute user={user} redirectPath="/" isAllowed={!!user && user.roles.includes('admin')}> <GestionPrecios token={token} setToken={setToken} user={user} setUser={setUser}/> </ProtectedRoute>}/>
            <Route path="/gestion_usuarios" element={<ProtectedRoute user={user} redirectPath="/" isAllowed={!!user && user.roles.includes('admin')}> <GestionUsuarios token={token} setToken={setToken} user={user} setUser={setUser}/> </ProtectedRoute>}/>
            <Route path="/gestion_pedidos/:pid" element={<ProtectedRoute user={user} redirectPath="/" isAllowed={!!user && (user.roles.includes('admin') || user.roles.includes('trabajador'))}> <DetallesPedido token={token} setToken={setToken} user={user} setUser={setUser}/> </ProtectedRoute>}/>

            {/*
            <Route path="courses" element={<Courses/>} />
            <Route path="course/:cid" element={<CourseDetail/>} />
            <Route path="course/:cid/lesson/:lid" element={<LessonDetail ejercicios={ejercicios} setEjercicios={setEjercicios}/>} />
            <Route path="course/:cid/lesson/:lid/ex/:eid" element={<ExerciseDetail ejercicios={ejercicios}/>} />
            */}
            <Route path="*" element={<h1>404</h1>}></Route>
            </Routes>
            </Container>
            <Footer/>
          </BrowserRouter>
        </>
      );
}