import * as React from 'react'
import { Link } from "react-router-dom";
import {AppBar,Typography,Grid,Box,Stack,Menu,MenuItem, IconButton, Button} from '@mui/material';
import logo from './../logo.jpg'
import MenuIcon from '@mui/icons-material/Menu';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useTranslation } from 'react-i18next';
import { withNamespaces } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';
import PublicIcon from '@mui/icons-material/Public';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { EuroOutlined } from '@mui/icons-material';
import Burbujas from './Burbujas';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

export default function NavBarLogin(props) {
    
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const {user,setUser}=props;

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {i18n.changeLanguage(lng);}
    //let languageStored=sessionStorage.getItem('Language') || 'es';
    let languageStored='es';
    const [language,setLanguage]=React.useState(languageStored);    
    function handleChange() {
        if (language==="es") {
            setLanguage("en");
            
        }
        if (language==="en") {
            setLanguage("es");
        }
    }

    React.useEffect(()=> {
        changeLanguage(language);
        localStorage.setItem("Language",language);
    }
    , [language])

    function logout() {
        setUser();
    }

    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('lg'));
    let tablet = useMediaQuery(theme.breakpoints.down ('lg'));
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    if (mobile) {tablet=false;}
    
return (
		<>
        {mobile?
        <>
        <AppBar position="static">
        <Stack direction="row" alignItems="center" justifyContent="space-evenly">
        <Box>
        <Typography  sx={{marginLeft:1,fontSize:'100%',paddingRight:1,borderRight:0,borderRightColor:'#503543'}} variant="overline">
            <span style={{fontWeight:"500"}}>Tintorería</span>
            <span style={{fontWeight:"200",fontSize:'90%'}}> de </span>
            <span style={{fontWeight:"500"}}>Sonia</span> 
        </Typography>
        </Box>
        <Typography color='#503543'>|</Typography>
        <Tooltip title="Inicio">
        <Link className="link-hover" to='/home'><HomeOutlinedIcon sx={{mt:1,ml:1}}/></Link>
        </Tooltip>
        <Tooltip title="Pedido">
        <Link className="link-hover" to='/pedidos'><ShoppingBasketOutlinedIcon sx={{mt:1,ml:1}}/></Link>
        </Tooltip>
        <Tooltip title="Login">
        <Link className="link-hover" to='/login'><PersonOutlineOutlinedIcon sx={{mt:1,ml:1}}/></Link>
        </Tooltip>
        </Stack>
        </AppBar>
        <Burbujas/>
        </>
        :tablet?
        <>
        <AppBar position="static">
        <Stack direction="row" alignItems="center" justifyContent="space-evenly">
        <Box>
        <Typography  sx={{marginLeft:1,fontSize:'120%',paddingRight:1,borderRight:0,borderRightColor:'#503543'}} variant="overline">
            <span style={{fontWeight:"200",fontSize:'90%'}}> La </span>
            <span style={{fontWeight:"500"}}>Tintorería</span>
            <span style={{fontWeight:"200",fontSize:'90%'}}> de </span>
            <span style={{fontWeight:"500"}}>Sonia</span> 
        </Typography>
        </Box>
        <Typography color='#503543'>|</Typography>
        <Tooltip title="Inicio">
        <Link className="link-hover" to='/home'><HomeOutlinedIcon sx={{mt:1,ml:1}}/></Link>
        </Tooltip>
        <Tooltip title="Pedido">
        <Link className="link-hover" to='/pedidos'><ShoppingBasketOutlinedIcon sx={{mt:1,ml:1}}/></Link>
        </Tooltip>
        <Tooltip title="Login">
        <Link className="link-hover" to='/login'><PersonOutlineOutlinedIcon sx={{mt:1,ml:1}}/></Link>
        </Tooltip>
        </Stack>
        </AppBar>
        <Burbujas/>
        </>
        :
        <>
	    <AppBar position="static" sx={{ display: { xs: 'block', sm:'block', md: 'block', lg: 'block',xl:'block' }}}>
            <Grid  display="flex" alignItems="center" justifyContent="space-between" direction="row" sx={{marginRight:10,marginLeft:10}}>
            <Stack sx={{mt:0.5,mb:0.5}} direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Box
                component="img"
                sx={{height: 130}}
                alt="Tintorería Sonia"
                src={logo}
                align="center"
                />
            <Stack direction="column" justifyContent="center" alignItems="center">    
            <Typography  color="success" sx={{marginLeft:2,fontSize:'160%'}} variant="overline"><span style={{fontWeight:"200"}}>La </span><span style={{fontWeight:"500"}}>Tintorería</span><span style={{fontWeight:"200"}}> de </span><span style={{fontWeight:"500"}}>Sonia</span></Typography>
            <Typography sx={{fontWeight:"200",mt:-2}} variant="body2">TINTORERÍA TRADICIONAL DE SEVILLA</Typography>
            </Stack>
            </Stack>
            <Stack direction="row" alignItems="center">
            <HomeOutlinedIcon sx={{mr:1}}/>
            <Typography variant="overline">
                <Link   className="link-hover" to='/home'>Home</Link>
            </Typography>
            </Stack>
            <Stack direction="row" alignItems="center">
            <ShoppingBasketOutlinedIcon sx={{mr:1}}/>
            <Typography variant="overline">
                <Link className="link-hover" to='/pedidos'>Pedido</Link>
            </Typography>
            </Stack>
            <Stack direction="row" alignItems="center">
            <PersonOutlineOutlinedIcon/>
            <Typography variant="overline">
                <Link className="link-hover" to='/login'>Login</Link>
            </Typography>
            </Stack>  
            </Grid>
        </AppBar>
        <Burbujas/>
        </>
        }
        </>
)}