import { Button, Typography } from '@mui/material';
import * as React from 'react'
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import {useRef,useState,useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import {AuthContext} from '../App';
import axios from "axios";
import jwt from 'jwt-decode'
import { EditNote } from '@mui/icons-material';
import Alert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


export default function Cliente(props) {

    let { params } = useParams();
    const { t, i18n } = useTranslation();
    //const {user}=props;
    const {token,setToken,user,setUser} = React.useContext(AuthContext);
    //const inputAlumnoFocus=useRef(null);
    const inputNombreFocus=useRef(null);
    const inputTelefono1Focus=useRef(null);
    const inputTelefono2Focus=useRef(null);
    const inputDireccion1Focus=useRef(null);
    const inputDireccion2Focus=useRef(null);
    
    let alumnoAlmacenado=localStorage.getItem('Alumno') || '';
    alumnoAlmacenado='dato por completar'
    const [editNombre,setEditNombre]=useState('');
    const [editTelefono1,setEditTelefono1]=useState('');
    const [editTelefono2,setEditTelefono2]=useState('');
    const [editDireccion1,setEditDireccion1]=useState('');
    const [editDireccion2,setEditDireccion2]=useState('');

    const [valorNombre,setValorNombre]=useState('');
    const [valorTelefono1,setValorTelefono1]=useState('');
    const [valorTelefono2,setValorTelefono2]=useState('');
    const [valorDireccion1,setValorDireccion1]=useState('');
    const [valorDireccion2,setValorDireccion2]=useState('');

    const [customer,setCustomer]=useState({});
    const [nombreCliente,setNombreCliente]=useState('');
    const [telefonos,setTelefonos]=useState('');
    const [direcciones,setDirecciones]=useState([]);

    const [pedidosCustomer,setPedidosCustomer]=useState([]);

    const [updated,setUpdated]=useState(false);

    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('lg'));
    let tablet = useMediaQuery(theme.breakpoints.down ('lg'));
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    if (mobile) {tablet=false;}
    
      function handleClickNombre (e){
        //console.log("handleClickNombre",e.target.value);
        setValorNombre(e.target.value);
      }

      function handleClickTelefono1 (e){
        //console.log("handleClickAlumno",e.target.value);
        setValorTelefono1(e.target.value);
      }

      function handleClickTelefono2 (e){
        //console.log("handleClickAlumno",e.target.value);
        setValorTelefono2(e.target.value);
      }

      function handleClickDireccion1 (e){
        //console.log("handleClickAlumno",e.target.value);
        setValorDireccion1(e.target.value);
      }

      function handleClickDireccion2 (e){
        //console.log("handleClickAlumno",e.target.value);
        setValorDireccion2(e.target.value);
      }

      function guardar() {
        const nombre=inputNombreFocus.current.value;
        const telefono1=inputTelefono1Focus.current.value;
        const telefono2=inputTelefono2Focus.current.value;
        const direcccion1=inputDireccion1Focus.current.value;
        const direcccion2=inputDireccion2Focus.current.value;
        console.log("guardar",nombre,telefono1,telefono2,direcccion1,direcccion2);
        const dataCliente= {
          "first_name": nombre,
          "telephones": JSON.stringify({"1":telefono1,"2":telefono2}),
          "addresses": JSON.stringify({"1":direcccion1,"2":direcccion2}), 
        }

        const userId=jwt(token).id;
        const urlCliente=process.env.REACT_APP_API_URL+`auth/actualizar_cliente/${customer.id}`;
        console.log(dataCliente,urlCliente);
        //axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;  
        
        axios
        .put(urlCliente,
          dataCliente,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          })
        .then((response) => {
          //console.log("Pedido successful", response);
          setUpdated(true);
         })
         .catch((error) => {
          console.error("Pedido error");
         });
         
      }

      React.useEffect(()=> {
        console.log('token',token);
        if (token)
        {
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;  
          const userId=jwt(token).id;
          //console.log('userId',userId);
          const urlCustomerData=process.env.REACT_APP_API_URL+`cliente/pedidos/datos_cliente/${userId}`;
          axios
          .get(urlCustomerData)
          .then((response) => {
            console.log("Customer data", response.data);
            setCustomer(response.data);
          })
          .catch((error) => {
            console.error("Customer data error",error.response.status);
            if (error.response.status===401)
            {
            setToken(null)
            setUser(null);
            localStorage.removeItem("token");
            alert('Autentificación necesaria. Por favor, vuelva a entrar en el sistema')
            }          
            });

          const urlCustomerPedidosData=process.env.REACT_APP_API_URL+`cliente/pedidos/obtener_pedidos_cliente/${userId}`;
          axios
          .get(urlCustomerPedidosData)
          .then((response) => {
            console.log("Customer Pedidos data", response.data);
            //setCustomer(response.data);
            setPedidosCustomer(response.data);
          })
          .catch((error) => {
            console.error("Customer Pedidos error");
          });
        }
       }
       , [token])

       React.useEffect(()=> {
        if (customer!==undefined)
        {
        //console.log('customer',customer);
        //console.log('customer',customer.first_name);
        if (customer.first_name) {setValorNombre(customer.first_name);}
        if (customer.telephones) {
          //setTelefonos(Object.values(JSON.parse(customer.telephones.replaceAll('\'','"'))).join('-'));
          setValorTelefono1(Object.values(JSON.parse(customer.telephones.replaceAll('\'','"')))[0])
          setValorTelefono2(Object.values(JSON.parse(customer.telephones.replaceAll('\'','"')))[1])

        }
        if (customer.addresses) {
          //setDirecciones(Object.values(JSON.parse(customer.addresses.replaceAll('\'','"'))));
          setValorDireccion1(Object.values(JSON.parse(customer.addresses.replaceAll('\'','"')))[0])
          setValorDireccion2(Object.values(JSON.parse(customer.addresses.replaceAll('\'','"')))[1])

        }
        //console.log('customer',customer.addresses.replaceAll('\'','"'));
        //console.log('customer',JSON.parse(customer.addresses.replaceAll('\'','"')));
        //console.log('customer',customer.telephones);
        } 

      }
       , [customer])

       /*
       React.useEffect(()=> {
       console.log("valor nombre;",valorNombre);
        }
        , [valorNombre])
    */
    return(
        <>
      <Paper sx={{p:2,width:'100%'}} elevation={3} >
      {
      tablet?
      <>
      <Typography  color="success" sx={{marginLeft:2,fontSize:'140%'}} variant="overline"><span style={{fontWeight:"200"}}>Hola, </span><span style={{fontWeight:"500"}}>{user.name}</span></Typography>
        <Card sx={{m:0,p:2,pr:0,mt:1,pt:0,width:'100%'}}>
        <Typography  color="success" sx={{marginLeft:0,m:0,p:0,fontSize:'120%'}} variant="overline"><span style={{fontWeight:"500"}}>Datos </span> <span style={{fontWeight:"500"}}>Cliente</span></Typography>              <Grid  item sx={{mb:2}}>
                <TextField inputRef={inputNombreFocus} sx={{borderBottom:'1px solid #F9BF53',width:'80%'}} InputProps={{disabled:!editNombre}} required autoComplete='on' label="Nombre" size="medium" id="usuario" variant='outlined' value={valorNombre} onChange={handleClickNombre}/>
                <IconButton onClick={(e)=>{setEditNombre(true)}}>
                  <EditIcon sx={{color:'#F9BF53',m:0,p:0}}/>
                </IconButton>
              </Grid>
              <Grid  item sx={{mb:2}}>
                <TextField  inputRef={inputTelefono1Focus} sx={{borderBottom:'1px solid #F9BF53',width:'80%'}} InputProps={{disabled:!editTelefono1}} required autoComplete='on' label="Teléfono" size="medium" id="usuario" variant='outlined' value={valorTelefono1} onChange={handleClickTelefono1}/>
                <IconButton onClick={(e)=>{setEditTelefono1(true)}}>
                  <EditIcon sx={{color:'#F9BF53',m:0,p:0}}/>
                </IconButton>
              </Grid>
              <Grid  item sx={{mb:2}}>
                <TextField  inputRef={inputTelefono2Focus} sx={{borderBottom:'1px solid #F9BF53',width:'80%'}} InputProps={{disabled:!editTelefono2}} required autoComplete='on' label="Teléfono 2" size="medium" id="usuario" variant='outlined'  value={valorTelefono2} onChange={handleClickTelefono2}/>
                <IconButton onClick={(e)=>{setEditTelefono2(true)}}>
                  <EditIcon sx={{color:'#F9BF53',m:0,p:0}}/>
                </IconButton>
              </Grid>
              <Grid  item sx={{mb:2}}>
                <TextField fullWidth inputRef={inputDireccion1Focus} sx={{borderBottom:'1px solid #F9BF53',width:'80%'}} InputProps={{disabled:!editDireccion1}} required autoComplete='on' label="Dirección principal" size="large" id="usuario" variant='outlined' value={valorDireccion1} onChange={handleClickDireccion1}/>
                <IconButton onClick={(e)=>{setEditDireccion1(true)}}>
                  <EditIcon sx={{color:'#F9BF53',m:0,p:0}}/>
                </IconButton>
              </Grid>
              <Grid  item sx={{mb:2}}>
                <TextField fullWidth inputRef={inputDireccion2Focus} sx={{borderBottom:'1px solid #F9BF53',width:'80%'}} InputProps={{disabled:!editDireccion2}} required autoComplete='on' label="Dirección alternativa" size="medium" id="usuario" variant='outlined'  value={valorDireccion2} onChange={handleClickDireccion2}/>
                <IconButton onClick={(e)=>{setEditDireccion2(true)}}>
                  <EditIcon sx={{m:0,p:0,color:'#F9BF53'}}/>
                </IconButton>
              </Grid>
              <Grid alignItems="center" item>
              <Button sx={{mt:1, mb:1}}size="normal" color="warning" variant="outlined" onClick={guardar}>Guardar</Button>
              </Grid>
              {
                updated?
                <Alert severity="success">Datos actualizados</Alert>
                :
                <></>
              }
        </Card>
        <Card sx={{mt:2,p:2,pt:0,width:'100%'}}>
            <Typography  color="success" sx={{marginLeft:0,m:0,p:0,fontSize:'120%'}} variant="overline"><span style={{fontWeight:"500"}}>Últimos </span> <span style={{fontWeight:"500"}}>Pedidos</span></Typography>
            {
              pedidosCustomer.map(item=> 
                <Typography sx={{mb:1, fontSize:'90%',color:"#626466"}} variant="body2">Fecha: {item.fecha.split("T")[0]} . Albarán: {item.codigo}</Typography>

              )

            }
              </Card>
      </>
      :mobile?
      <>
      <Typography  color="success" sx={{marginLeft:0,mb:1,pb:1,fontSize:'95%'}} variant="overline"><span style={{fontWeight:"500"}}>Datos </span><span style={{fontWeight:"200"}}>de </span><span style={{fontWeight:"500"}}>{user.name}</span></Typography>
        <Card sx={{m:0,p:1,pr:0,mt:1,pt:2,width:'100%'}}>
              <Grid  item sx={{mb:2}}>
                <TextField inputRef={inputNombreFocus} sx={{borderBottom:'1px solid #F9BF53',width:'80%'}} InputProps={{disabled:!editNombre}} required autoComplete='on' label="Nombre" size="medium" id="usuario" variant='outlined' value={valorNombre} onChange={handleClickNombre}/>
                <IconButton onClick={(e)=>{setEditNombre(true)}}>
                  <EditIcon sx={{color:'#F9BF53',m:0,p:0}}/>
                </IconButton>
              </Grid>
              <Grid  item sx={{mb:2}}>
                <TextField  inputRef={inputTelefono1Focus} sx={{borderBottom:'1px solid #F9BF53',width:'80%'}} InputProps={{disabled:!editTelefono1}} required autoComplete='on' label="Teléfono" size="medium" id="usuario" variant='outlined' value={valorTelefono1} onChange={handleClickTelefono1}/>
                <IconButton onClick={(e)=>{setEditTelefono1(true)}}>
                  <EditIcon sx={{color:'#F9BF53',m:0,p:0}}/>
                </IconButton>
              </Grid>
              <Grid  item sx={{mb:2}}>
                <TextField  inputRef={inputTelefono2Focus} sx={{borderBottom:'1px solid #F9BF53',width:'80%'}} InputProps={{disabled:!editTelefono2}} required autoComplete='on' label="Teléfono 2" size="medium" id="usuario" variant='outlined'  value={valorTelefono2} onChange={handleClickTelefono2}/>
                <IconButton onClick={(e)=>{setEditTelefono2(true)}}>
                  <EditIcon sx={{color:'#F9BF53',m:0,p:0}}/>
                </IconButton>
              </Grid>
              <Grid  item sx={{mb:2}}>
                <TextField fullWidth inputRef={inputDireccion1Focus} sx={{borderBottom:'1px solid #F9BF53',width:'80%'}} InputProps={{disabled:!editDireccion1}} required autoComplete='on' label="Dirección principal" size="large" id="usuario" variant='outlined' value={valorDireccion1} onChange={handleClickDireccion1}/>
                <IconButton onClick={(e)=>{setEditDireccion1(true)}}>
                  <EditIcon sx={{color:'#F9BF53',m:0,p:0}}/>
                </IconButton>
              </Grid>
              <Grid  item sx={{mb:2}}>
                <TextField fullWidth inputRef={inputDireccion2Focus} sx={{borderBottom:'1px solid #F9BF53',width:'80%'}} InputProps={{disabled:!editDireccion2}} required autoComplete='on' label="Dirección alternativa" size="medium" id="usuario" variant='outlined'  value={valorDireccion2} onChange={handleClickDireccion2}/>
                <IconButton onClick={(e)=>{setEditDireccion2(true)}}>
                  <EditIcon sx={{m:0,p:0,color:'#F9BF53'}}/>
                </IconButton>
              </Grid>
              <Grid alignItems="center" item>
              <Button sx={{mt:1, mb:1}}size="normal" color="warning" variant="outlined" onClick={guardar}>Guardar</Button>
              </Grid>
              {
                updated?
                <Alert severity="success">Datos actualizados</Alert>
                :
                <></>
              }
        </Card>
        <Card sx={{mt:2,p:1,width:'100%'}}>
            <Typography  color="success" sx={{marginLeft:0,mb:1,pb:1,fontSize:'95%'}} variant="overline"><span style={{fontWeight:"500"}}>Últimos </span> <span style={{fontWeight:"500"}}>Pedidos</span></Typography>
            {
              pedidosCustomer.map(item=> 
                <Typography sx={{mb:1, fontSize:'90%',color:"#626466"}} variant="body2">{item.fecha.split("T")[0]} . Albarán: {item.codigo}</Typography>

              )

            }
              </Card>
      </>
      :desktop?
      <> 
      <Typography  color="success" sx={{marginLeft:2,fontSize:'160%'}} variant="overline"><span style={{fontWeight:"200"}}>Hola, </span><span style={{fontWeight:"500"}}>{user.name}</span></Typography>
          <Grid direction="row" sx={{width:'100%'}} container spacing={1} alignItems="left" justifyContent="left">
          <Card sx={{m:2,p:2,pl:4,pr:4,width:'60%'}}>
          <Typography  color="success" sx={{marginLeft:0,fontSize:'160%'}} variant="overline"><span style={{fontWeight:"500",fontSize:'80%'}}>Datos </span><span style={{fontWeight:"200",fontSize:'80%'}}>de </span><span style={{fontWeight:"500",fontSize:'80%'}}>{user.name}</span></Typography>
            <Grid  item sx={{mb:2}}>
              <TextField inputRef={inputNombreFocus} sx={{borderBottom:'1px solid #F9BF53',width:'90%'}} InputProps={{disabled:!editNombre}} required autoComplete='on' label="Nombre" size="medium" id="usuario" variant='outlined' value={valorNombre} onChange={handleClickNombre}/>
              <IconButton onClick={(e)=>{setEditNombre(true)}}>
                <EditIcon sx={{color:'#F9BF53'}}/>
              </IconButton>
            </Grid>
            <Grid  item sx={{mb:2}}>
              <TextField  inputRef={inputTelefono1Focus} sx={{borderBottom:'1px solid #F9BF53'}} InputProps={{disabled:!editTelefono1}} required autoComplete='on' label="Teléfono" size="medium" id="usuario" variant='outlined' value={valorTelefono1} onChange={handleClickTelefono1}/>
              <IconButton onClick={(e)=>{setEditTelefono1(true)}}>
                <EditIcon sx={{color:'#F9BF53'}}/>
              </IconButton>
            </Grid>
            <Grid  item sx={{mb:2}}>
              <TextField  inputRef={inputTelefono2Focus} sx={{borderBottom:'1px solid #F9BF53'}} InputProps={{disabled:!editTelefono2}} required autoComplete='on' label="Teléfono 2" size="medium" id="usuario" variant='outlined'  value={valorTelefono2} onChange={handleClickTelefono2}/>
              <IconButton onClick={(e)=>{setEditTelefono2(true)}}>
                <EditIcon sx={{color:'#F9BF53'}}/>
              </IconButton>
            </Grid>
            <Grid  item sx={{mb:2}}>
              <TextField fullWidth inputRef={inputDireccion1Focus} sx={{borderBottom:'1px solid #F9BF53',width:'90%'}} InputProps={{disabled:!editDireccion1}} required autoComplete='on' label="Dirección principal" size="large" id="usuario" variant='outlined' value={valorDireccion1} onChange={handleClickDireccion1}/>
              <IconButton onClick={(e)=>{setEditDireccion1(true)}}>
                <EditIcon sx={{color:'#F9BF53'}}/>
              </IconButton>
            </Grid>
            <Grid  item sx={{mb:2}}>
              <TextField fullWidth inputRef={inputDireccion2Focus} sx={{borderBottom:'1px solid #F9BF53',width:'90%'}} InputProps={{disabled:!editDireccion2}} required autoComplete='on' label="Dirección alternativa" size="medium" id="usuario" variant='outlined'  value={valorDireccion2} onChange={handleClickDireccion2}/>
              <IconButton onClick={(e)=>{setEditDireccion2(true)}}>
                <EditIcon sx={{color:'#F9BF53'}}/>
              </IconButton>
            </Grid>
            <Grid alignItems="center" item>
            <Button sx={{mt:2, mb:2}}size="normal" color="warning" variant="outlined" onClick={guardar}>Guardar</Button>
            </Grid>
            {
              updated?
              <Alert severity="success">Datos actualizados</Alert>
              :
              <></>
            }
            </Card>
            <Card sx={{m:2,p:2}}>
            <Typography  color="success" sx={{marginLeft:0,fontSize:'160%'}} variant="overline"><span style={{fontWeight:"500",fontSize:'80%'}}>Últimos </span> <span style={{fontWeight:"500",fontSize:'80%'}}>Pedidos</span></Typography>
            {
              pedidosCustomer.map(item=> 
                <Typography sx={{mb:1, fontSize:'90%',color:"#626466"}} variant="body2">Fecha: {item.fecha.split("T")[0]} . Albarán: {item.codigo}</Typography>

              )

            }
              </Card>
          </Grid>
      </>
      :
      <>Not known</>  
      }       
        </Paper>       
        
        </>
    );
}