import * as React from 'react'
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {AppBar,Grid,Box,Stack,Menu,MenuItem, IconButton, Button} from '@mui/material';

export default function Copyright(props) {
	const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

return (
	<>
	{
	mobile?<>
		<AppBar position="static" sx={{mt:3,p:1, borderTop:1, borderTopColor:'#B0ADAB'}}>
		<Box>
			<Stack direction="column" justifyContent="center">
			<Typography sx={{fontWeight:"200",mt:0,pl:0,fontSize:'70%'}} variant="body2"> © Tintorería de Sonia. </Typography>
			<Typography sx={{fontWeight:"200",mt:0,pr:0,fontSize:'70%'}} variant="body2"> Trabaja con nosotros. <span style={{fontWeight:"200"}}>admin@tintoreriasonia.com</span></Typography>
			</Stack>
			</Box>
	</AppBar>
	</>
	:
		<>
	<AppBar position="static" sx={{mt:3,p:1, borderTop:1, borderTopColor:'#B0ADAB'}}>
		<Box>
			<Stack direction="row" justifyContent="space-between">
			<Typography sx={{fontWeight:"300",mt:0,pl:1}} variant="body2"> © Tintorería de Sonia. </Typography>
			<Typography sx={{fontWeight:"200",mt:0,pr:1,fontSize:'75%'}} variant="body2"> Trabaja con nosotros. <span style={{fontWeight:"200"}}>admin@tintoreriasonia.com</span></Typography>
			</Stack>
			</Box>
			</AppBar>
		</>
	}
	</>
)}
