import * as React from 'react'
import {useRef,useState,useEffect} from 'react';
import { Typography } from '@mui/material';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { GoogleLoginButton,createButton } from "react-social-login-buttons";
import { useNavigate} from "react-router-dom";
import Paper from '@mui/material/Paper';
import axios from "axios";
import jwt from 'jwt-decode'
import {AuthContext} from '../App';
import Alert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function PasswordReset(props) {

    const {token,setToken} = React.useContext(AuthContext);
    const {user,setUser}=props;
    const navigate = useNavigate()
    let { token_param } = useParams();
    const { t, i18n } = useTranslation();
    const inputEmail=useRef();
    const inputPassword=useRef();
    const [alert,setAlert]=useState(0);
    //const [token,setToken]=useState('');
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    React.useEffect(()=> {
        console.log('token',token);
        console.log('token_param',token_param);
        if (token)
        {
            const userId=jwt(token).id;
            const userRole=jwt(token).role || 'cliente';
            const userName=jwt(token).sub;
            const roles=[userRole];
            console.log(jwt(token),userId,userRole,userName);
            setUser({name:userName,roles:roles,permissions:[]});
        }
       }
       , [token])

    function handlePasswordReset() {
        try {
        const username=jwt(token_param).sub;
        const password='da igual';
        let urlLogin=process.env.REACT_APP_API_URL+"auth/token_cliente_reset_password";
        if (username==='santi' || username==='rocio' || username==='luis' || username.endsWith('@ts'))
        {urlLogin=process.env.REACT_APP_API_URL+'auth/token_user'}   
        let bodyFormData = new FormData();
        bodyFormData.append("username", username);
        bodyFormData.append("password",password);
        axios
        .post(urlLogin, bodyFormData)
        .then((response) => {
        console.log("Password reset successful", response.data.access_token);
        setToken(response.data.access_token);
        localStorage.setItem('token',response.data.access_token)
        })
        .catch((error) => {
        console.error("Password reset error",error.response);
        //alert('error')
        setAlert(1)
        });

        }
        catch {
            console.log("error");
            setAlert(1)
        }
    }
       
    function handleLogin() {
        //console.log("login",inputEmail.current.value)
        /*
        {
            id: '1',
            name: 'robin',
            permissions: ['analyze'],
            roles: ['admin'],
          }
        */
        if (inputEmail.current.value==="admin")
        {setUser({name:"admin",roles:['admin'],permissions:[]});}
        //if (inputEmail.current.value==="trabajador")
        //{setUser({name:"trabajador",roles:['trabajador'],permissions:[]});}
        //if (inputEmail.current.value==="cliente")
        //{setUser({name:"cliente",roles:['cliente'],permissions:[]});}
        const username=inputEmail.current.value;
        const password=inputPassword.current.value;
        let urlLogin=process.env.REACT_APP_API_URL+"auth/token_cliente";
        if (username==='santi' || username==='rocio' || username==='luis' || username.endsWith('@ts'))
        {urlLogin=process.env.REACT_APP_API_URL+'auth/token_user'}   
        let bodyFormData = new FormData();
        bodyFormData.append("username", username);
        bodyFormData.append("password",password);
        axios
        .post(urlLogin, bodyFormData)
        .then((response) => {
        console.log("Sign in successful", response.data.access_token);
        setToken(response.data.access_token);
        localStorage.setItem('token',response.data.access_token)
        })
        .catch((error) => {
        console.error("Sign in error",error.response);
        //alert('error')
        setAlert(1)
        });
            
    }
    function handleGoogleLogin() {

    }

    function handleCrearCuenta() {

    }

    useEffect(() => {
        if (user) {
        //console.log("user");
        if (user.roles.includes('admin') || user.roles.includes('trabajador')) {navigate('/gestion_pedidos')}
        if (user.roles.includes('cliente')) {navigate('/cliente')}
    }
      }, [user]);

    return(
        <>
        <Paper sx={{p:2,backgroundColor:"#ffffff",width:'100%',margin:'auto'}} elevation={3} >
        {mobile?
        <Grid container sx={{m:0}} spacing={0}>
            <Typography  sx={{marginLeft:1,fontSize:'95%',paddingRight:1,borderRight:0,borderRightColor:'#503543'}} variant="overline">
                <span style={{fontWeight:"200"}}>Iniciar</span><span style={{fontWeight:"500",fontSize:'90%'}}> Sesión </span> 
                </Typography>

                    <Button sx={{mt:1,mb:1,width:'100%'}} color="warning"  variant="outlined" onClick={handleLogin}>Entrar</Button>
                    {/*
                    <Typography sx={{m:0}} color="success" variant="body" >- o -</Typography>
                    <GoogleLoginButton style={{width:300,color:"#626466"}} align="center" onClick={handleGoogleLogin}/>
                    */}
                    {
              alert===1?
              <Alert sx={{mt:1 }}severity="error" size="small">Password reset incorrecto</Alert>
              :
              <></>
            }
        </Grid>
        :<>
            
        <Box  sx={{ p: 2}}>
            <Stack direction="column" spacing={2} alignItems="center">
                    <Typography  color="success" sx={{fontSize:'160%',textAlign:'center',m:0}} variant="overline">
                    <span style={{fontWeight:"200"}}>Password </span>
                    <span style={{fontWeight:"500"}}>Reset</span>
                    </Typography>        
                           <Button sx={{mb:0}} color="warning" style={{width: 300}} variant="outlined" onClick={handlePasswordReset}>Entrar</Button>
                    {/*
                    <Typography sx={{m:0}} color="success" variant="body" >- o -</Typography>
                    <GoogleLoginButton style={{width:300,color:"#626466"}} align="center" onClick={handleGoogleLogin}/>
                    */}
                    </Stack>
                    {
              alert===1?
              <Alert sx={{mt:1 }}severity="error" size="small">Password reset incorrecto</Alert>
              :
              <></>
            }
        </Box>
        {/* 
        <Grid container sx={{ml:0,mb:1, mt:2}} spacing={1} alignItems="center" direction="column">

        <Button color="primary" style={{width: 300}} variant="contained" onClick={handleCrearCuenta}>Crear una cuenta</Button>
        </Grid>
        */}
        </>
        }
        </Paper>
        </>
    );

}