import { CardContent, IconButton, Typography } from '@mui/material';
import * as React from 'react'
import {useRef,useState,useEffect} from 'react';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { DataGrid,  GridToolbarContainer,GridActionsCellItem,GridRowEditStopReasons,GridRowModes} from '@mui/x-data-grid';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { GoogleLoginButton } from "react-social-login-buttons";
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import axios from "axios";
import jwt from 'jwt-decode'
import Alert from '@mui/material/Alert';
import Currency from 'currency.js'
import currency from 'currency.js';
import {AuthContext} from '../App';
import FormGroup from '@mui/material/FormGroup';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from "react-router-dom";
import { NoEncryption } from '@mui/icons-material';
import condiciones from './condiciones.txt'
import { Helmet } from 'react-helmet';

export default function Pedidos(props) {

    let { params } = useParams();
    const { t, i18n } = useTranslation();
    //const {user,setUser}=props;
    const {token,setToken,user,setUser} = React.useContext(AuthContext);
    const inputEmail=useRef();
    const inputPassword=useRef();

    const unregisteredName=useRef();
    const unregisteredPhone=useRef();
    const unregisteredAddress=useRef();
    const unregisteredEmail=useRef();
    const unregisteredPassword=useRef();

    //Selecciones Productos
    const cantidadSeleccionada=useRef();
    const productoSeleccionado=useRef();

    /////
    const [productosDB,setProductosDB]=useState([]);
    const [options,setOptions]=useState([]);
    const [allProducts,setAllProducts]=useState({});
    const [rows,setRows]=useState([]);
    const [itemId,setItemId]=useState(0);
    const [urgente,setUrgente]=useState(false);
    const [guardarropa,setGuardarropa]=useState(false);
    const [customer,setCustomer]=useState({})
    const [haveAccount,setHaveAccount]=useState(false);
    const [registering,setRegistering]=useState(false);

    const [nombreCliente,setNombreCliente]=useState('');
    const [telefonos,setTelefonos]=useState('');
    const [direcciones,setDirecciones]=useState([]);
    const [direccionActiva,setDireccionActiva]=useState('');;
    const [horaEnvio,setHoraEnvio]=useState('');
    const [diaEnvio,setDiaEnvio]=useState('');

    const [activeStep, setActiveStep] = React.useState(0);
    const [erroValidacion,setErrorValidacion]=React.useState(0);

    const [finished,setFinished]=React.useState(false);

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

//PRODUCTOS
    React.useEffect(()=> {
      fetchProductos();      
  }
  , [])


  function fetchProductos() {
    const urlFetch=process.env.REACT_APP_API_URL+'productos';
    fetch(urlFetch)
    .then(response => {return response.json()})
    .then(data => {
        setProductosDB(data);
    })
    .catch(error=> {console.log(error)})
  }


//AUTENTIFICACION
// Example POST method implementation:

//SELECT
  const cantidades=[...Array(100).keys()].filter(num=>num!==0).map(num=>num.toString())

  React.useEffect(()=> {
    if (productosDB.length!==0)
    {  
      //console.log("productos",productosDB);
      const nombres=productosDB.filter(productoDB=>productoDB.servicio===false).map((producto)=>{
        //console.log("prod",producto);
        return {'title':producto.nombre,'firstLetter':producto.nombre.toUpperCase().charAt(0),'id':producto.id}
        }
      );
      const productsDict={};
      productosDB.map((producto)=>{
        //console.log("prod",producto);
        productsDict[producto.id]={'nombre':producto.nombre,'precio':producto.precio,'servicio':producto.servicio};
        }
      );
      //console.log("nombres",nombres);
      //console.log("productsDict",productsDict);
      setOptions(nombres);
      setAllProducts(productsDict);  
    }
    }
  , [productosDB])

  React.useEffect(()=> {
   // console.log('rows',rows);
  }
  , [rows])

  React.useEffect(()=> {
    //console.log('token',token);
    if (token)
    {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;  
    const userId=jwt(token).id;
    //console.log('userId',userId);
    const urlCustomerData=process.env.REACT_APP_API_URL+`cliente/pedidos/datos_cliente/${userId}`;
    axios
    .get(urlCustomerData)
    .then((response) => {
      //console.log("Customer data", response.data);
      setCustomer(response.data);
     })
     .catch((error) => {
      console.error("Customer data error",error.response.status);
      if (error.response.status===401)
            {
            setHaveAccount(true);
            }
     });
    }
   }
   , [token])

   React.useEffect(()=> {
    if (customer!==undefined)
    {
    console.log('customer',customer);
    //console.log('customer',customer.first_name);
    if (customer.first_name) {setNombreCliente(customer.first_name);}
    if (customer.telephones) {setTelefonos(Object.values(JSON.parse(customer.telephones.replaceAll('\'','"'))).join(' '));}
    if (customer.addresses) {
      setDirecciones(Object.values(JSON.parse(customer.addresses.replaceAll('\'','"'))));
      setDireccionActiva(Object.values(JSON.parse(customer.addresses.replaceAll('\'','"')))[0])}
    if (Object.keys(customer).length>0) {setHaveAccount(true);}
    //console.log('customer',customer.addresses.replaceAll('\'','"'));
    //console.log('customer',JSON.parse(customer.addresses.replaceAll('\'','"')));
    //console.log('customer',customer.telephones);
    } 
  }
   , [customer])

   React.useEffect(()=> {
     //console.log('activeStep',activeStep);
     //if (activeStep===2) {setRegistering(false)}
   }
   , [activeStep])

   React.useEffect(()=> {
    //console.log('direccionActiva',direccionActiva);
  }
  , [direccionActiva])

  React.useEffect(()=> {
    //console.log('haveAccount',haveAccount);
  }
  , [haveAccount])

  function handleCheckUrgente (event) {
    setUrgente(event.target.checked);
    if (event.target.checked) {addServicio('Urgente');}
    else {deleteServicio('Urgente');}
  }
  
  function handleCheckGuardarropa(event) {
    setGuardarropa(event.target.checked);
    if (event.target.checked) {addServicio('Guardarropa');}
    else {deleteServicio('Guardarropa');}
  }


  function addItem () {
    let cantidad=cantidadSeleccionada.current.value;
    let productoId=-1;
    if (productosDB.filter(producto=>producto.nombre===productoSeleccionado.current.value).length>0)
    {productoId=productosDB.filter(producto=>producto.nombre===productoSeleccionado.current.value)[0].id };

    if (productoId>0 && cantidad>0)
      {
      const prenda=allProducts[productoId].nombre;
      let precio=allProducts[productoId].precio;
      //const money = Dinero({ amount: precio, currency: 'EUR' })
      let servicio=allProducts[productoId].servicio;
      if (servicio) {cantidad=1;}
      const subtotal=currency(precio).multiply(cantidad).value;
      //const subtotal=precio*cantidad;
      //console.log('money',money,subtotal);
      //const subtotal=money.multiply(parseInt(cantidad));
      //console.log("precio",precio, cantidad, precio*cantidad, "subototal",subtotal);
      const currentItemsFiltered=[...rows].filter(row=>row.id!=='TOTAL' && row.servicio===false);
      const currentServiciosFiltered=[...rows].filter(row=>row.servicio===true);
      const itemsAndServiciosFiltered=[...rows].filter(row=>row.id!=='TOTAL');
      //IDs

      setItemId(itemId+1)
      //let itemId=0;
      //if (itemsAndServiciosFiltered.length>0) 
      //  {
      //      itemId=itemsAndServiciosFiltered[itemsAndServiciosFiltered.length-1].id+1;
      //  }
      //NUEVO ITEM
      let precioTotal=0;
      const newItem={'id': itemId,'item': prenda,'precio':subtotal,'cantidad': cantidad,'preciounitario':precio,'codProducto':productoId,'servicio':false}
      //console.log('new item',newItem);
      precioTotal=subtotal;
      //PRECIOS
      if (currentItemsFiltered.length>0) 
        {
          precioTotal=currency(precioTotal).add(currentItemsFiltered.map(row=>row.precio)
          .reduce((accumulator, currentValue) => currency(accumulator).add(currentValue)))
        }
      if (currentServiciosFiltered.length>0) 
        {
          precioTotal=currency(precioTotal).add(currentServiciosFiltered.map(row=>row.precio)
          .reduce((accumulator, currentValue) => currency(accumulator).add(currentValue)))
      }
      const filaTotal={'id':'TOTAL','total':precioTotal}
      const currentRows=[...currentItemsFiltered,newItem,...currentServiciosFiltered,filaTotal]
      setRows([...currentRows])
      //cantidadSeleccionada.current.value='0';
    }
  }

  function deleteItem(e,id) {
    e.stopPropagation();
    //console.log('idid',id);
    const rowsPrev=[...rows].filter(fila=>fila.id!==id).filter(fila=>fila.id!=='TOTAL');
    let precioTotal=0;
    if (rowsPrev.length>0)
    {
      //precioTotal=rowsPrev.map(row=>row.precio).reduce((accumulator, currentValue) => accumulator + currentValue);
      precioTotal=currency(precioTotal).add(rowsPrev.map(row=>row.precio)
      .reduce((accumulator, currentValue) => currency(accumulator).add(currentValue)))
    }
    const filaTotal={'id':'TOTAL','total':precioTotal}
    //console.log('rowsPrev',rowsPrev);
    const currentRows=[...rowsPrev,filaTotal]
    setRows([...currentRows])
  }

  function addServicio (servicio) {
    let cantidad=1;
    const productoId=productosDB.filter(producto=>producto.nombre===servicio)[0].id || -1;
    const prenda=allProducts[productoId].nombre;
    //console.log("prodId",productoId,prenda);
    const precio=currency(allProducts[productoId].precio);
   

    const subtotal=currency(precio).multiply(cantidad).add(0).value;
    //console.log("antes",[...rows]);
    let currentRowsFiltered=[...rows].filter(row=>row.id!=='TOTAL').filter(row=>row.item!==servicio);
    //console.log("despues",currentRowsFiltered);
    let precioTotal=0;
    setItemId(itemId+1)
    //let itemId=0;
    //if (currentRowsFiltered.length>0) 
    //{
    //  itemId=currentRowsFiltered[currentRowsFiltered.length-1].id+1;
    //} 
    const newItem={'id': itemId,'item': prenda,'precio':subtotal,'cantidad': cantidad,'preciounitario':precio,'codProducto':productoId,'servicio':true}
    precioTotal=subtotal;
    if (currentRowsFiltered.length>0) 
    {
    //precioTotal=currentRowsFiltered.map(row=>row.precio).reduce((accumulator, currentValue) => accumulator + currentValue)+precioTotal
    precioTotal=currency(precioTotal).add(currentRowsFiltered.map(row=>row.precio)
    .reduce((accumulator, currentValue) => currency(accumulator).add(currentValue)))
    }
    const filaTotal={'id':'TOTAL','total':precioTotal}
    const currentRows=[...currentRowsFiltered,newItem,filaTotal]
    setRows([...currentRows])
  }

  function deleteServicio (servicio) {
    const currentRowsFiltered=[...rows].filter(row=>row.id!=='TOTAL').filter(row=>row.item!==servicio);
    let precioTotal=0;
    if (currentRowsFiltered.length>0) 
    {
    //precioTotal=currentRowsFiltered.map(row=>row.precio).reduce((accumulator, currentValue) => accumulator + currentValue)+precioTotal
    precioTotal=currency(precioTotal).add(currentRowsFiltered.map(row=>row.precio)
    .reduce((accumulator, currentValue) => currency(accumulator).add(currentValue)))
    }
    const filaTotal={'id':'TOTAL','total':precioTotal}
    const currentRows=[...currentRowsFiltered,filaTotal]
    setRows([...currentRows])
  }
  function handleLogin() {
    const username=inputEmail.current.value;
    const password=inputPassword.current.value;
    const urlLogin=process.env.REACT_APP_API_URL+"auth/token_cliente"
    let bodyFormData = new FormData();
    bodyFormData.append("username", username);
    bodyFormData.append("password",password);
    axios
    .post(urlLogin, bodyFormData)
    .then((response) => {
      //console.log("Sign in successful", response.data.access_token);
      setToken(response.data.access_token);
      localStorage.setItem('token',response.data.access_token)
     })
     .catch((error) => {
      console.error("Sign in error");
     });
    }

  function handleGoogleLogin() {

  }

  function handleCrearCuenta() {

  }

 
  const columns = [
      { field: 'id', headerName: 'ID', width: 10,hidden:true },
      {
      field: 'categoria',
          headerName: 'categoria',
          //width: '15%',
          flex:3,
          editable: false,
          hidden:true,  
          sortable:false,     
        },
      {
        field: 'item',
        headerName: 'Prenda',
        //width: 150,
        flex:1,
        editable: false,
        sortable:false,     
        colSpan: ({ row }) => {
          if (row.id === 'SUBTOTAL' || row.id === 'TOTAL') {
            return 3;
          }
          if (row.id === 'TAX') {
            return 2;
          }
          return undefined;
        },
        valueGetter: ({ value, row }) => {
          //console.log('row.id',row.id)
          if (row.id === 'SUBTOTAL' || row.id === 'TAX' || row.id === 'TOTAL') {
            return row.id;
          }
          else {
          return value;
          }
        },
      },

      {
        field: 'cantidad',
        headerName: 'Cantidad',
        type: 'number',
        //width: 100,
        editable: false,
        sortable:false,     
        flex:1,
      },
      {
        field: 'preciounitario',
        headerName: 'Precio',
        type: 'number',
        sortable:false,     
        //width: 100,
        flex:1,
        valueGetter: ({ row }) => {
          //return row.preciounitario+'€';
          const EUR = value => currency(value, {
            precision: 2
          });
          return EUR(row.preciounitario)+'€';
        },
      },
      {
        field: 'precio',
        headerName: 'Total',
        type: 'number',
        //align:'center',
        //width: 100,
        flex:1,
        sortable:false,     
        valueGetter: ({ row }) => {
          const EUR = value => currency(value, {
            precision: 2
          });
          if (row.id === 'SUBTOTAL') {
            return row.subtotal;
          }
          if (row.id === 'TAX') {
            return row.taxTotal;
          }
          if (row.id === 'TOTAL') {
            const EUR = value => currency(value, {
              precision: 2
            });
            return EUR(row.total)+'€';
          }
          return EUR(row.precio)+'€';
        },
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: '',
        width: 100,
        sortable:false,     
        cellClassName: 'actions',
        renderCell: (params) => {
          //console.log("params.id",params.row.servicio);
          return (
            params.row.id!=='TOTAL' && !params.row.servicio?
            <>
            <IconButton size="small"
              onClick={(e) => deleteItem(e, params.row.id)}
              variant="contained"
            >
              <DeleteIcon sx={{fontSize:'90%'}}size="small"/>
            </IconButton>
            </>
            :
            <>
            </>
          
          );
        } 
      },
    ];

  const columns2 = [
    { field: 'id', headerName: 'ID', width: 10,hidden:true },
    {
    field: 'categoria',
        headerName: 'categoria',
        //width: '15%',
        flex:3,
        editable: false,
        hidden:true,       
      },
    {
      field: 'item',
      headerName: 'Prenda',
      //width: 150,
      flex:1,
      editable: false,
      sortable:false,
      colSpan: ({ row }) => {
        if (row.id === 'SUBTOTAL' || row.id === 'TOTAL') {
          return 3;
        }
        if (row.id === 'TAX') {
          return 2;
        }
        return undefined;
      },
      valueGetter: ({ value, row }) => {
        //console.log('row.id',row.id)
        if (row.id === 'SUBTOTAL' || row.id === 'TAX' || row.id === 'TOTAL') {
          return row.id;
        }
        else {
        return value;
        }
      },
    },

    {
      field: 'cantidad',
      headerName: 'Cantidad',
      type: 'number',
      //width: 100,
      editable: false,
      flex:1,
      sortable:false,
    },
    {
      field: 'preciounitario',
      headerName: 'Precio',
      type: 'number',
      //width: 100,
      flex:1,
      sortable:false,
      valueGetter: ({ row }) => {
          //return row.preciounitario+'€';
          const EUR = value => currency(value, {
            precision: 2
          });
          return EUR(row.preciounitario)+'€';},
    },
    {
      field: 'precio',
      headerName: 'Total',
      type: 'number',
      //align:'center',
      //width: 100,
      flex:1,
      sortable:false,
      valueGetter: ({ row }) => {
        const EUR = value => currency(value, {
          precision: 2
        });
        if (row.id === 'SUBTOTAL') {
          return row.subtotal;
        }
        if (row.id === 'TAX') {
          return row.taxTotal;
        }
        if (row.id === 'TOTAL') {
          const EUR = value => currency(value, {
            precision: 2
          });
          return EUR(row.total)+'€';
        }
        return EUR(row.precio)+'€';
      },
    },
  ];

  const columnsMobile = [
    { field: 'id', headerName: 'ID', width: 10,hidden:true },
    {
    field: 'categoria',
        headerName: 'categoria',
        //width: '15%',
        flex:1,
        editable: false,
        hidden:true,  
        sortable:false,     
      },
    {
      field: 'item',
      headerName: 'Prenda',
      minWidth:85,
      flex:2,
      editable: false,
      sortable:false,     
      colSpan: ({ row }) => {
        if (row.id === 'SUBTOTAL' || row.id === 'TOTAL') {
          return 3;
        }
        if (row.id === 'TAX') {
          return 2;
        }
        return undefined;
      },
      valueGetter: ({ value, row }) => {
        //console.log('row.id',row.id)
        if (row.id === 'SUBTOTAL' || row.id === 'TAX' || row.id === 'TOTAL') {
          return row.id;
        }
        else {
        return value;
        }
      },
    },

    {
      field: 'cantidad',
      headerName: 'Uds',
      type: 'number',
      //cellClass: 'my-class',
      //width: 100,
      editable: false,
      sortable:false,     
      //flex:0.5,
      width:5,
      align:'center',
      headerAlign:'left'
    },
    {
      field: 'preciounitario',
      headerName: 'Precio',
      type: 'number',
      sortable:false,     
      //width: 100,
      flex:1.25,
      minWidth: 50,
      valueGetter: ({ row }) => {
        //return row.preciounitario+'€';
        const EUR = value => currency(value, {
          precision: 2
        });
        return EUR(row.preciounitario)+'€';
      },
    },
    {
      field: 'precio',
      headerName: 'Total',
      type: 'number',
      //align:'center',
      //width: 100,
      flex:1.25,
      minWidth:70,
      sortable:false,     
      valueGetter: ({ row }) => {
        const EUR = value => currency(value, {
          precision: 2
        });
        if (row.id === 'SUBTOTAL') {
          return 'h'+row.subtotal;
        }
        if (row.id === 'TAX') {
          return row.taxTotal;
        }
        if (row.id === 'TOTAL') {
          const EUR = value => currency(value, {
            precision: 2
          });
          return EUR(row.total)+'€';
        }
        return EUR(row.precio)+'€';
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      //minWidth:10,
      width:5,
      sortable:false,
      cellClassName: 'actions',
      renderCell: (params) => {
        //console.log("params.id",params.row.servicio);
        return (
          params.row.id!=='TOTAL' && !params.row.servicio?
          <>
          <IconButton size="small"
            onClick={(e) => deleteItem(e, params.row.id)}
            variant="contained"
          >
            <DeleteIcon sx={{fontSize:'70%'}}size="small"/>
          </IconButton>
          </>
          :
          <>
          </>
        
        );
      } 
    },
  ];

  const columnsMobile2 = [
    { field: 'id', headerName: 'ID', width: 10,hidden:true },
    {
    field: 'categoria',
        headerName: 'categoria',
        //width: '15%',
        flex:1,
        editable: false,
        hidden:true,  
        sortable:false,     
      },
    {
      field: 'item',
      headerName: 'Prenda',
      minWidth:85,
      flex:2,
      editable: false,
      sortable:false,     
      colSpan: ({ row }) => {
        if (row.id === 'SUBTOTAL' || row.id === 'TOTAL') {
          return 3;
        }
        if (row.id === 'TAX') {
          return 2;
        }
        return undefined;
      },
      valueGetter: ({ value, row }) => {
        //console.log('row.id',row.id)
        if (row.id === 'SUBTOTAL' || row.id === 'TAX' || row.id === 'TOTAL') {
          return row.id;
        }
        else {
        return value;
        }
      },
    },

    {
      field: 'cantidad',
      headerName: 'Uds',
      type: 'number',
      //cellClass: 'my-class',
      //width: 100,
      editable: false,
      sortable:false,     
      //flex:0.5,
      width:5,
      align:'center',
      headerAlign:'left'
    },
    {
      field: 'preciounitario',
      headerName: 'Precio',
      type: 'number',
      sortable:false,     
      //width: 100,
      flex:1.25,
      minWidth: 50,
      valueGetter: ({ row }) => {
        //return row.preciounitario+'€';
        const EUR = value => currency(value, {
          precision: 2
        });
        return EUR(row.preciounitario)+'€';
      },
    },
    {
      field: 'precio',
      headerName: 'Total',
      type: 'number',
      //align:'center',
      //width: 100,
      flex:1.25,
      minWidth:70,
      sortable:false,     
      valueGetter: ({ row }) => {
        const EUR = value => currency(value, {
          precision: 2
        });
        if (row.id === 'SUBTOTAL') {
          return 'h'+row.subtotal;
        }
        if (row.id === 'TAX') {
          return row.taxTotal;
        }
        if (row.id === 'TOTAL') {
          const EUR = value => currency(value, {
            precision: 2
          });
          return EUR(row.total)+'€';
        }
        return EUR(row.precio)+'€';
      },
    },
  ];  
  const getCellClassName = ({ row, field }) => {
    //console.log(field,row)
    if (row.id === 'SUBTOTAL' || row.id === 'TOTAL' || row.id === 'TAX') {
      if (field === 'item' || row.id === 'TOTAL') {
        return 'total';
      }
    }
    if (row.servicio) {
      return 'servicio';
    }
    return '';
  };



  function stepBack() {
    const step=activeStep;
    setActiveStep(step-1)
  }

  function stepForward() {
    //console.log(customer,Object.keys(customer).length,typeof customer==='undefined', typeof customer==='null')
    const step=activeStep;
    if (activeStep===1 && Object.keys(customer).length>0) {
        //console.log("2 paso logeado");
        //console.log(diaEnvio,horaEnvio,direccionActiva);
        if (!direccionActiva || direccionActiva==='') {
            setErrorValidacion(1);        
          }
        else if (!diaEnvio || diaEnvio==='') {
          setErrorValidacion(2);        

        }
        else if (!horaEnvio || horaEnvio==='') {
          setErrorValidacion(3);        
        }
        else {
          setErrorValidacion(0);    
          setActiveStep(step+1);
        }
    }
    else {
    setActiveStep(step+1)
    }
    //console.log('activeStep',activeStep);
    //console.log('d,h',diaEnvio,horaEnvio);
  }
      
  function stepForwardNoRegistered() {
    if (activeStep===1 && Object.keys(customer).length==0)
    {
      const step=activeStep;
      const nombre=unregisteredName.current.value;
      const direccion=unregisteredAddress.current.value;
      const telefono=unregisteredPhone.current.value;
      let email=unregisteredEmail.current.value;
      let contra=unregisteredPassword.current.value;
      const reMail =/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
      const reTel=/^[0-9]{9}$/;
      const reNombre=/[a-zA-Z ]{6}/;
      const reDireccion=/[a-zA-Z 0-9]{6}/;
      const reContra=/[a-zA-Z0-9]{6}/;
      if (!nombre.match(reNombre)) {setErrorValidacion(11);}
      else if (!direccion.match(reDireccion)) {setErrorValidacion(12);}
      else if (!telefono.match(reTel)) {setErrorValidacion(13);}
      else if (!diaEnvio || diaEnvio==='') {setErrorValidacion(14)}
      else if (!horaEnvio || horaEnvio==='') {setErrorValidacion(15);}
      else if (email!=='' && !email.match(reMail)) {setErrorValidacion(16);}
      else if (contra!=='' && !contra.match(reContra)) {setErrorValidacion(17);}
      else {
          setRegistering(true);  
          if (email==='') {email=nombre.replaceAll(' ','')+'-'+Date.now().toString()+'@ts.com';}
          if (contra==='') {contra='hola123';}  
          const urlNuevoCliente=process.env.REACT_APP_API_URL+'auth/create_cliente';
          const telefonos=JSON.stringify({'1':telefono,'2':''})
          const direcciones=JSON.stringify({'1':direccion,'2':''})
          const dataCliente= {
            "email": email,
            "username": email,
            "first_name": nombre,
            "telephones": telefonos,
            "addresses": direcciones,
            "password":contra,
            "is_active":true,
          }
          console.log(nombre,direccion,telefono,email,contra,step);
          console.log(urlNuevoCliente);
          console.log(dataCliente);
        axios
        .post(urlNuevoCliente,
          dataCliente,
          )
        .then((response) => {
          //console.log("Nuevo cliente successful", response);
          //setFinished(true);
            const urlLogin=process.env.REACT_APP_API_URL+"auth/token_cliente"
            let bodyFormData = new FormData();
            bodyFormData.append("username", email);
            bodyFormData.append("password",contra);
            axios
            .post(urlLogin, bodyFormData)
            .then((response) => {
              //console.log("Sign in successful", response.data.access_token);
              setToken(response.data.access_token);
              localStorage.setItem('token',response.data.access_token);
              setActiveStep(step+1);
            })
            .catch((error) => {
              console.error("Sign in error");
              setRegistering(false);
            })
            .finally();
          })
          .catch((error) => {
          console.error("Nuevo cliente error");
          setRegistering(false);
          })
          .finally();
      }


    }
  

}

  function finalize() {
    //alert("Bien hecho!");
    const hayPrendas=rows.filter(row=>row.id!=='TOTAL' && row.servicio===false).length>0;
    //console.log(hayPrendas);
    if (hayPrendas) {
      const timestampPedido = Date.now(); 
      const clienteIdPedido=customer.id;
      const direccionPedido=direccionActiva;
      const precioPedido=rows.filter(row=>row.id==='TOTAL')[0].total;
      const pagadoPedido=0;
      const estadoPedido='Pendiente';
      const codigoPedido='';
      const observacionesPedido='';
      const prendasPedido=JSON.stringify(rows.filter(row=>row.id!=='TOTAL'));
      const horarioPedido=diaEnvio+' '+horaEnvio
      const dataPedido= {
        "fecha": timestampPedido,
        "cliente": clienteIdPedido,
        "direccion": direccionPedido,
        "precio": precioPedido,
        "pagado": pagadoPedido,
        "estado": estadoPedido,
        "codigo": codigoPedido,
        "prendas": prendasPedido,
        "observaciones": observacionesPedido,
        "horario":horarioPedido
      }
      const urlPedido=process.env.REACT_APP_API_URL+'cliente/pedidos/crear_pedido';
      //axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;  
      axios
      .post(urlPedido,
        dataPedido,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        })
      .then((response) => {
        //console.log("Pedido successful", response);
        setFinished(true);
       })
       .catch((error) => {
        console.error("Pedido error",error.response.status);
        if (error.response.status===401)
        {
        setToken(null)
        setUser(null);
        setCustomer({});
        localStorage.removeItem("token");
        alert('Autentificación necesaria. Por favor, vuelva a entrar en el sistema');
        stepBack();
        }
       });
    }


/*
{
  "fecha": "2023-10-16T08:53:03.273Z",
  "cliente": 0,
  "direccion": "string",
  "precio": 0,
  "pagado": 0,
  "estado": "string",
  "codigo": "string",
  "prendas": "string",
  "observaciones": "string"
}
    */
  }

  function handleChangeHaveAccount() {
    setHaveAccount(!haveAccount);
  }

  function verCondiciones() {
 //   fetch(condiciones)
 //   .then(r => r.text())
 //   .then(text => {
 //    console.log(text);
 //  });

   window.open('./condiciones.txt', '_blank');
  }

    return(
        <>
        <Helmet>

          <title>Tintoreria Sonia  - Pedidos</title>
          <meta
          name="description"
          content="Tintoreria Sonia. Pedidos online de limpieza y planchado de ropa."
    />
        </Helmet>
        <Paper sx={{p:1,width:'100%', '& .total':{backgroundColor: '#F8F5F1'}, '& .servicio':{fontSize:'90%'}}} elevation={3} >
        {mobile?
        <>
            <Grid container sx={{m:0}} spacing={0}>
                <Typography  sx={{marginLeft:1,fontSize:'95%',paddingRight:1,borderRight:0,borderRightColor:'#503543'}} variant="overline">
                <span style={{fontWeight:"500"}}>Pedido</span>
                </Typography>
                <Stepper sx={{ml:-0.5}} activeStep={activeStep} orientation="vertical">            
                <Step >
                <StepLabel>
                <Stack direction='row' spacing={2}  alignItems="flex-end" justifyContent="space-between">      
                  <Typography variant='overline' sx={{fontSize:'80%'}}> Elección de prendas</Typography>    
                </Stack>
                </StepLabel>
                <StepContent>
                  
                <Grid container sx={{m:0,width:'95%'}} spacing={0}>
                      <Autocomplete
                          id="grouped-demo"
                          options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                          groupBy={(option) => option.firstLetter}
                          getOptionLabel={(option) => option.title}
                          ListboxProps={{
                            sx: { fontSize: '80%' },
                          }}
                          sx={{
                            width: '100%',
                            '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                              fontSize: '80%',
                            },
                            ml:0,
                            mb:1
                          }}                    
                          renderInput={(params) => <
                            TextField {...params} sx={{mt:-2}} label="Prenda" variant="standard"
                            inputRef={productoSeleccionado}
                            disabled={productosDB.length===0}
                            />
                          }
                          />
                          <Autocomplete
                          id="grouped-demo"
                          options={cantidades}
                          ListboxProps={{
                            sx: { fontSize: '80%' },
                          }}
                          sx={{
                            width: '100%',
                            '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                              fontSize: '80%',
                            },
                          }}
                          renderInput={(params) => <
                            TextField {...params} sx={{mt:0,mb:1}} label="Cantidad" variant="standard"
                            inputRef={cantidadSeleccionada}
                            />}
                          disabled={productosDB.length===0}  
                          />

                        <Button  color="warning" variant="contained" onClick={addItem} sx={{ width: '100%',fontSize:'80%',mt:2,mb:2 }}>Añadir</Button>
                        <Stack direction="column" sx={{width:'100%'}}>
                        <DataGrid 
                        sx={{fontSize:'75%',justifyContent:'center',minHeight:'100px',mt:1,pr:0,ml:0,border:0
                        ,"& .MuiDataGrid-cell": {
                          padding:'0px',
                          margin:'0px',
                        },
                        "& .MuiDataGrid-header": {
                          padding:'0px',
                          margin:'0px',
                          //justifyContent:'center'
                        },
                        '.MuiDataGrid-columnSeparator': {
                          display: 'none',
                        },
                        '.MuiDataGrid-columnHeader': {
                          padding: '0px',
                        },
                      
                      
                        }}
                          rows={rows}
                          columns={columnsMobile}
                          columnVisibilityModel={{
                              // Hide columns status and traderName, the other columns will remain visible
                              id:false,
                              categoria: false,
                              //actions:false,
                          }}
                          initialState={{
                          //pagination: {
                          // paginationModel: {
                          //   pageSize: 5,
                          // },
                          //},
                          }}
                          //pageSizeOptions={[5]}
                          //checkboxSelection=
                          disableRowSelectionOnClick
                          hideFooter
                          density='compact'
                          disableColumnFilter
                          disableColumnMenu
                          getCellClassName={getCellClassName}
                          />
                          <Stack direction="row" sx={{mt:2,mb:2}}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                size="small"
                                name="Urgente"
                                onChange={handleCheckUrgente}
                                checked={urgente}
                              />
                            }
                            label={<Typography sx={{fontSize:'80%'}}variant="body2">Urgente (2 días)</Typography>}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                size="small"
                                name="Guardarropa"
                                onChange={handleCheckGuardarropa}
                                checked={guardarropa}

                              />
                            }
                            label={<Typography sx={{fontSize:'80%'}} variant="body2">Guardarropa (2 meses)</Typography>}
                          />
                        </Stack>
                        </Stack>
                        <Box sx={{ mt:2, mb: 0 }}>
                        <div>
                          <Button
                              variant="contained"
                              sx={{ mt: 1, mr: 1 }}
                              size="small"
                              disabled
                            >
                              Anterior
                            </Button>
                            <Button
                              variant="contained"
                              sx={{ mt: 1, mr: 1 }}
                              size="small"
                              onClick={stepForward}

                            >
                              {false ? 'Finish' : 'Siguiente'}
                            </Button>
                            </div>
                            </Box>
                </Grid>
                </StepContent>
                </Step>


                <Step>
                <StepLabel>
                    {activeStep===1 && Object.keys(customer).length===0?
                    <Stack direction='row' spacing={2}  alignItems="flex-end" justifyContent="space-between">      
                    <Typography variant='overline' sx={{fontSize:'80%'}}>Datos</Typography>
                    <FormGroup>
                    <FormControlLabel control={<Checkbox checked={haveAccount} onChange={handleChangeHaveAccount} size="small"/>} label={<Typography variant="overline" color="grey">Login</Typography>} labelPlacement="end"/>
                    </FormGroup>
                    </Stack>
                    :
                    <>
                  <Stack direction='row' spacing={2}  alignItems="flex-end" justifyContent="space-between">      
                    <Typography variant='overline' sx={{fontSize:'80%'}}>Datos</Typography>
                    </Stack>
                    </>
                    }
                </StepLabel>
                <StepContent>
                    {
                      haveAccount && Object.keys(customer).length===0?
                      <Grid container sx={{m:0,width:'90%'}} spacing={0}>
                          <TextField 
                            sx={{mb:1}}
                            inputProps={{style: {fontSize: '80%'}}} // font size of input text
                            InputLabelProps={{style: {fontSize: '80%'}}} // font size of input label
                          inputRef={inputEmail} style = {{width: '100%'}} required autoComplete='on' label="Email" id="email" variant='outlined' />
                          <TextField 
                          sx={{mb:1}}
                            inputProps={{style: {fontSize: '80%'}}} // font size of input text
                            InputLabelProps={{style: {fontSize: '80%'}}} // font size of input label
                          type="password" inputRef={inputPassword} style = {{width: '100%'}} required autoComplete='on' label="Contraseña"  id="contra" variant='outlined' />
                          <Button sx={{mb:1,width: '100%',fontSize:'80%'}} color="warning" variant="outlined" onClick={handleLogin}>Entrar</Button>
                          <Typography sx={{fontSize:'75%'}}><Link className="link-hover" sx={{mt:1,mb:1,fontSize:'50%'}} to='/forget_password'>Olvidé contraseña</Link></Typography>
                        <Box sx={{ mt:3, mb: 0 }}>
                                <div>
                              <Button
                                  variant="contained"
                                  sx={{ mt: 1, mr: 1 }}
                                  size="small"
                                  onClick={stepBack}
                                >
                                  Anterior
                                </Button>
                                <Button
                                  variant="contained"
                                  sx={{ mt: 1, mr: 1 }}
                                  size="small"
                                  onClick={stepForward}

                                >
                                  {false ? 'Finish' : 'Siguiente'}
                                </Button>
                              </div>
                   
                          </Box>
                        </Grid>
                      :!haveAccount && Object.keys(customer).length===0?
                      <Grid container sx={{m:0,width:'90%'}} spacing={0}>
                      <TextField 
                              inputProps={{style: {fontSize: '80%'}}} // font size of input text
                              InputLabelProps={{style: {fontSize: '80%'}}} // font size of input label
                              sx = {{width:'100%',mb:1}} inputRef={unregisteredName}  error={erroValidacion===11} required autoComplete='on' label="Nombre y apellido" variant='standard' />
                              <TextField  onChange={(event) => setDireccionActiva(event.target.value)} 
                              inputProps={{style: {fontSize: '80%'}}} // font size of input text
                              InputLabelProps={{style: {fontSize: '80%'}}} // font size of input label
                              sx = {{width:'100%',mb:1}} inputRef={unregisteredAddress} error={erroValidacion===12} required autoComplete='on' label="Dirección"  variant='standard' />
                              <TextField inputRef={unregisteredPhone} 
                              inputProps={{style: {fontSize: '80%'}}} // font size of input text
                              InputLabelProps={{style: {fontSize: '80%'}}} // font size of input label
                              sx = {{width:'100%',mb:1}} error={erroValidacion===13} required autoComplete='on' label="Teléfono"  variant='standard' />
                                <Autocomplete
                                    id="grouped-demo"
                                    options={['Lunes','Martes','Miércoles','Jueves','Viernes']}
                                    defaultValue={diaEnvio}
                                    onChange={(event, value) => setDiaEnvio(value)}
                                    ListboxProps={{
                                      sx: { fontSize: '80%' },
                                    }}
                                    sx={{
                                      width: '100%',
                                      '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                        fontSize: '80%',
                                      },
                                      ml:0,
                                      mb:1
                                    }}                                renderInput={(params) => <TextField error={erroValidacion===14} required  {...params} sx={{mt:-1,mb:2}} label="Día" variant="standard"/>}
                                    required
                                    />
                                    <Autocomplete
                                    id="grouped-demo"
                                    options={['08:00-15:00']}
                                    defaultValue={horaEnvio}
                                    onChange={(event, value) => setHoraEnvio(value)}
                                    ListboxProps={{
                                      sx: { fontSize: '80%' },
                                    }}
                                    sx={{
                                      width: '100%',
                                      '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                        fontSize: '80%',
                                      },
                                      ml:0,
                                      mb:0
                                    }} 
                                    renderInput={(params) => <TextField error={erroValidacion===15} required  {...params} sx={{mt:-1,mb:1}} label="Hora" variant="standard" />}
                                    />
                              <Typography sx={{mt:0,mb:0,color:'orange'}} variant='overline'>Opcional: crea cuenta</Typography>
                              <TextField  
                              sx={{mt:0}}
                              inputProps={{style: {fontSize: '80%'}}} // font size of input text
                              InputLabelProps={{style: {fontSize: '80%'}}} // font size of input label
                              error={erroValidacion===16} inputRef={unregisteredEmail} style = {{width: '95%'}} autoComplete='on' label="Email"  variant='standard' />
                              <TextField 
                              sx={{mt:0}} 
                              inputProps={{style: {fontSize: '80%'}}} // font size of input text
                              InputLabelProps={{style: {fontSize: '80%'}}} // font size of input label
                              error={erroValidacion===17} inputRef={unregisteredPassword} style = {{width: '95%'}} autoComplete='on' label="Contraseña"  variant='standard' />

                            {registering?
                              <>  
                              <Stack  sx={{mt:2}} direction="row" spacing={2} alignItems="left">
                              <Alert severity="warning"> Guardando los datos</Alert>
                              <CircularProgress color='warning' />  
                              </Stack>
                              </>
                              :
                              <></>
                              }
                            <Box sx={{ mt:2, mb: 0 }}>
                                    <div>
                                  <Button
                                      variant="contained"
                                      sx={{ mt: 1, mr: 1 }}
                                      size="small"
                                      onClick={stepBack}
                                    >
                                      Anterior
                                    </Button>
                                    <Button
                                      variant="contained"
                                      sx={{ mt: 1, mr: 1 }}
                                      size="small"
                                      onClick={stepForwardNoRegistered}
                                      disabled={registering}          
                                    >
                                      {false ? 'Finish' : 'Siguiente'}
                                    </Button>
                                  </div>
                                  <Typography variant="body2" sx={{fontWeight:'300',fontSize:'60%',mt:1.5,color:'grey'}}> 
                             Acepto las <span class="condiciones" style={{color:"blue"}}
                                onClick={verCondiciones}>condiciones</span> de servicio al hacer click en "siguiente".</Typography>
                              </Box>
                              
                        </Grid>  
                      :Object.keys(customer).length>0 && haveAccount?  
                        <Grid container sx={{m:0}} spacing={0}>

                        <Autocomplete 
                            id="grouped-demo"
                            options={[]}
                            value={nombreCliente}
                            ListboxProps={{
                              sx: { fontSize: '80%' },
                            }}
                            sx={{
                              width: '100%',
                              '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                fontSize: '80%',
                              },
                              ml:0,
                              mb:1
                            }}            
                            renderInput={(params) => <TextField  {...params} sx={{mt:-2,mb:2 }} label="Nombre" variant="standard"/>}
                            />
                            
                        <Autocomplete
                            id="grouped-demo"
                            onChange={(event, value) => setDireccionActiva(value)}
                            value={direccionActiva}
                            options={direcciones}
                            ListboxProps={{
                              sx: { fontSize: '80%' },
                            }}
                            sx={{
                              width: '100%',
                              '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                fontSize: '80%',
                              },
                              ml:0
                            }} 
                            renderInput={(params) => <TextField error={erroValidacion===1} {...params} sx={{mt:-1,mb:2}} label="Dirección" variant="standard"/>}
                          />
                            
                        <Autocomplete
                            id="grouped-demo"
                            value={telefonos}
                            options={[]}
                            ListboxProps={{
                              sx: { fontSize: '80%' },
                            }}
                            sx={{
                              width: '100%',
                              '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                fontSize: '80%',
                              },
                              ml:0
                            }}  
                            renderInput={(params) => <TextField {...params} sx={{mt:0,mb:2}} label="Teléfono(s)" variant="standard"/>}
                            />
                            
                        <Autocomplete
                            id="grouped-demo"
                            options={['Lunes','Martes','Miércoles','Jueves','Viernes']}
                            defaultValue={diaEnvio}
                            onChange={(event, value) => setDiaEnvio(value)}
                            error={erroValidacion===2}
                            ListboxProps={{
                              sx: { fontSize: '80%' },
                            }}
                            sx={{
                              width: '100%',
                              '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                fontSize: '80%',
                              },
                              ml:0
                            }}                          
                            renderInput={(params) => <TextField error={erroValidacion===2} {...params} sx={{mt:0,mb:2}} label="Día" variant="standard"/>}
                            />
                        <Autocomplete
                            id="grouped-demo"
                            options={['08:00-15:00']}
                            defaultValue={horaEnvio}
                            onChange={(event, value) => setHoraEnvio(value)}
                            error={erroValidacion===3}
                            ListboxProps={{
                              sx: { fontSize: '80%' },
                            }}
                            sx={{
                              width: '100%',
                              '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                fontSize: '80%',
                              },
                              ml:0
                            }} renderInput={(params) => <TextField error={erroValidacion===3} {...params} sx={{mt:0,mb:2}} label="Hora" variant="standard" />}
                            />
                      <Box sx={{ mt:2, mb: 0 }}>
                                    <div>
                            <Button
                                variant="contained"
                                sx={{ mt: 1, mr: 1 }}
                                size="small"
                                onClick={stepBack}
                              >
                                Anterior
                              </Button>
                              <Button
                                variant="contained"
                                sx={{ mt: 1, mr: 1 }}
                                size="small"
                                onClick={stepForward}
                              >
                                {false ? 'Finish' : 'Siguiente'}
                              </Button>
                              
                              </div>
                        </Box>
                        </Grid>
                      :
                      <>
                      </>
                  }
                </StepContent>  
                </Step>
                <Step>
                    <StepLabel>
                    <Typography  variant='overline' sx={{fontSize:'80%'}}>Confirmación</Typography>
                    </StepLabel>
                    <StepContent>
                    {
                      rows.filter(row=>row.id!=='TOTAL' && row.servicio===false).length===0?
                      <>
                      <Alert severity="warning">Elija las prendas en el primer paso</Alert>
                    </>
                    :Object.keys(customer)==0?
                        <>
                        <Alert severity="warning">Complete los datos</Alert>
                      </>
                    :rows.filter(row=>row.id!=='TOTAL' && row.servicio===false).length>0?
                    <>
                    <Stack sx={{m:1}} direction="row" spacing={10}>
                          <DataGrid
                          sx={{fontSize:'75%',justifyContent:'center',minHeight:'100px',mt:1,pr:0,ml:0,border:0
                          ,"& .MuiDataGrid-cell": {
                            padding:'0px',
                            margin:'0px',
                          },
                          "& .MuiDataGrid-header": {
                            padding:'0px',
                            margin:'0px',
                            //justifyContent:'center'
                          },
                          '.MuiDataGrid-columnSeparator': {
                            display: 'none',
                          },
                          '.MuiDataGrid-columnHeader': {
                            padding: '0px',
                          },
                          }}
                            rows={rows}
                            columns={columnsMobile2}
                            columnVisibilityModel={{
                                // Hide columns status and traderName, the other columns will remain visible
                                id:false,
                                categoria: false,
                            }}
                            initialState={{
                            //pagination: {
                            // paginationModel: {
                            //   pageSize: 5,
                            // },
                            //},
                            }}
                            //pageSizeOptions={[5]}
                            //checkboxSelection=
                            disableRowSelectionOnClick
                            hideFooter
                            density='compact'
                            disableColumnFilter
                            disableColumnMenu
                            getCellClassName={getCellClassName}
                            />
                        </Stack>    
                    <Card sx={{m:1,p:0}}>
                          <CardHeader
                          sx={{

                            '.MuiCardHeader-title':{fontSize:'80%'},
                            '.MuiCardHeader-subheader':{fontSize:'80%'},
                            mb:0,pb:1

                          }}
                          avatar={
                            <Avatar sx={{ bgcolor:'#ebe2d6',color:'black' }} aria-label="recipe">
                              {nombreCliente[0]}
                            </Avatar>
                          }
                          title={nombreCliente}
                          subheader={telefonos}
                        />
                        <CardContent sx={{mt:0,pt:0,
                          '.MuiCardContent':{fontSize:'80%'}}}>
                        <Typography sx={{fontSize:'80%',mb:0.5}} variant="body2">{direccionActiva}</Typography>
                        <Typography sx={{fontSize:'80%'}} variant="body2">{diaEnvio+'. '+horaEnvio+'h'}</Typography>
                        </CardContent>
                      </Card>
                      </>
                      :
                      <>
                      </>
                      } 
                      {
                        !finished?
                        <>
                        <Button
                            variant="contained"
                            sx={{ mt: 1, mr: 1 }}
                            size="small"
                            onClick={stepBack}
                          >
                            Anterior
                        </Button>
                        <Button
                          variant="outlined"
                          sx={{ mt: 1, mr: 1 }}
                          size="medium"
                          onClick={finalize}
                          color="warning"
                        >
                          Realizar
                        </Button>
                          </>
                          :
                          <>
                        <Alert severity="success">Pedido realizado. En breve nos pondremos en contacto telefónico, para confirmar el pedido.</Alert>
                        </>
                      }
                    </StepContent>
                </Step>
                </Stepper>
            </Grid>
        </>
        :<>
            <Typography  color="success" sx={{marginLeft:2,fontSize:'160%'}} variant="overline"><span style={{fontWeight:"500"}}>Pedido</span><span style={{fontWeight:"500"}}> Online</span>
            </Typography>
            <Stepper activeStep={activeStep} orientation="vertical">
            <Step >
              <StepLabel>
              <Typography variant='overline' sx={{fontSize:'100%'}}> Elección de prendas </Typography>    
              </StepLabel>
                <StepContent>
                  
                    <Stack direction="row" sx={{mb:2}} spacing={4} alignItems="center">
                    <Autocomplete
                        id="grouped-demo"
                        options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                        groupBy={(option) => option.firstLetter}
                        getOptionLabel={(option) => option.title}
                        sx={{ width: 300}}
                        renderInput={(params) => <
                          TextField {...params} sx={{mt:-2}} label="Prenda" variant="standard"
                          inputRef={productoSeleccionado}
                          disabled={productosDB.length===0}
                          />
                        }
                        />
                        <Autocomplete
                        id="grouped-demo"
                        options={cantidades}
                        sx={{ width: 150 }}
                        renderInput={(params) => <
                          TextField {...params} sx={{mt:-2}} label="Cantidad" variant="standard"
                          inputRef={cantidadSeleccionada}
                          />}
                        disabled={productosDB.length===0}  
                        />
                        <Button  color="warning" variant="contained" onClick={addItem} size="medium">Añadir</Button>
                        
                    </Stack>
                    <Stack direction="column" sx={{mb:0}} spacing={4}>
                      <DataGrid sx={{p:0,m:0,justifyContent:'center',minHeight:'100px','& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {display: 'none' }}}
                        rows={rows}
                        columns={columns}
                        columnVisibilityModel={{
                            // Hide columns status and traderName, the other columns will remain visible
                            id:false,
                            categoria: false,
                        }}
                        initialState={{
                        //pagination: {
                        // paginationModel: {
                        //   pageSize: 5,
                        // },
                        //},
                        }}
                        //pageSizeOptions={[5]}
                        //checkboxSelection=
                        disableRowSelectionOnClick
                        hideFooter
                        density='compact'
                        disableColumnFilter
                        disableColumnMenu
                        getCellClassName={getCellClassName}
                        />
                        <Stack direction="row">
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              name="Urgente"
                              onChange={handleCheckUrgente}
                              checked={urgente}
                            />
                          }
                          label={<Typography sx={{fontSize:'80%'}}variant="body2">Urgente (2 días)</Typography>}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              name="Guardarropa"
                              onChange={handleCheckGuardarropa}
                              checked={guardarropa}

                            />
                          }
                          label={<Typography sx={{fontSize:'80%'}} variant="body2">Guardarropa (2 meses en nuestras instalaciones)</Typography>}
                        />
                      </Stack>
                        <Stack direction="row" sx={{mb:0}} spacing={4} alignItems="center">

                        <Button
                            variant="contained"
                            sx={{ mt: 1, mr: 1 }}
                            size="small"
                            disabled
                          >
                            Anterior
                          </Button>
                          <Button
                            variant="contained"
                            sx={{ mt: 1, mr: 1 }}
                            size="small"
                            onClick={stepForward}

                          >
                            {false ? 'Finish' : 'Siguiente'}
                          </Button>
                          </Stack>
                    </Stack>
                </StepContent>
            </Step>
            <Step>
            <StepLabel>
                <Stack direction='row' spacing={10}   justifyContent="flex-start">          
                <Typography  variant='overline' sx={{fontSize:'100%'}}>Datos</Typography>
                {activeStep===1 && Object.keys(customer).length===0?
                <FormGroup>
                <FormControlLabel control={<Checkbox checked={haveAccount} onChange={handleChangeHaveAccount} size="small"/>} label={<Typography variant="overline" color="grey">Login con email</Typography>} labelPlacement="start"/>
                </FormGroup>
                :
                <></>
                }
                </Stack>
            </StepLabel>
            <StepContent>
                {
                  haveAccount && Object.keys(customer).length===0?
                    <>
                      <Stack direction="row" spacing={2} alignItems="center">
                      <Stack direction="column" spacing={2} alignItems="center">
                      <TextField inputRef={inputEmail} style = {{width: 300}} required autoComplete='on' label="Email" id="email" variant='outlined' />
                      <TextField type="password" inputRef={inputPassword} style = {{width: 300}} required autoComplete='on' label="Contraseña"  id="contra" variant='outlined' />
                      <Button sx={{mb:0}} color="warning" style={{width: 300}} variant="outlined" onClick={handleLogin}>Entrar</Button>
                      <Typography sx={{fontSize:'80%'}}><Link className="link-hover" sx={{mt:1,mb:1,fontSize:'50%'}} to='/forget_password'>Olvidé contraseña</Link></Typography>
                      </Stack> 
                    </Stack>
                    
                    <Box sx={{ mt:2, mb: 0 }}>
                            <div>
                          <Button
                              variant="contained"
                              sx={{ mt: 1, mr: 1 }}
                              size="small"
                              onClick={stepBack}
                            >
                              Anterior
                            </Button>
                            <Button
                              variant="contained"
                              sx={{ mt: 1, mr: 1 }}
                              size="small"
                              onClick={stepForward}

                            >
                              {false ? 'Finish' : 'Siguiente'}
                            </Button>
                          </div>
                          
                      </Box>
                    </>
                  :!haveAccount && Object.keys(customer).length===0?
                    <>
                          <Stack direction="row" spacing={2} alignItems="center">
                          <Box  sx={{ p: 0, border:0, borderRadius: 5, borderColor:'#EBE2D6'}}>

                          <Stack direction="column" spacing={2} alignItems="center">
                          <TextField inputRef={unregisteredName} style = {{width: 400}} error={erroValidacion===11} required autoComplete='on' label="Nombre y apellido" variant='standard' />
                          <TextField  onChange={(event) => setDireccionActiva(event.target.value)} inputRef={unregisteredAddress} style = {{width: 400}} error={erroValidacion===12} required autoComplete='on' label="Dirección"  variant='standard' />
                          <TextField inputRef={unregisteredPhone} style = {{width: 400}} error={erroValidacion===13} required autoComplete='on' label="Teléfono"  variant='standard' />
                          <Stack sx={{mt:1}} direction="row" spacing={0}>
                            <Autocomplete
                                id="grouped-demo"
                                options={['Lunes','Martes','Miércoles','Jueves','Viernes']}
                                defaultValue={diaEnvio}
                                onChange={(event, value) => setDiaEnvio(value)}
                                sx={{ width: 200,ml:2 }}
                                renderInput={(params) => <TextField error={erroValidacion===14} required  {...params} sx={{mt:-1,mb:2}} label="Día" variant="standard"/>}
                                required
                                />
                                <Autocomplete
                                id="grouped-demo"
                                options={['08:30-15:00']}
                                defaultValue={horaEnvio}
                                onChange={(event, value) => setHoraEnvio(value)}
                                
                                sx={{ width: 200,ml:2 }}
                                renderInput={(params) => <TextField error={erroValidacion===15} required  {...params} sx={{mt:-1,mb:2}} label="Hora" variant="standard" />}
                                
                                />
                          </Stack>
                          <Typography sx={{mt:0,mb:0,color:'orange'}} variant='overline'>Opcional: crea una cuenta para próximos pedidos</Typography>
                          <TextField  error={erroValidacion===16} inputRef={unregisteredEmail} style = {{width: 400,marginTop:-0}} autoComplete='on' label="Email"  variant='standard' />
                          <TextField  error={erroValidacion===17} inputRef={unregisteredPassword} style = {{width: 400}} autoComplete='on' label="Contraseña"  variant='standard' />
                          </Stack> 
                          </Box>
                        </Stack>
                        {registering?
                          <>  
                          <Stack  sx={{mt:2}} direction="row" spacing={2} alignItems="center">
                          <Alert severity="warning"> Guardando los datos</Alert>
                          <CircularProgress color='warning' />  
                          </Stack>
                          </>
                          :
                          <></>
                          }
                        <Box sx={{ mt:4, mb: 0 }}>
                                <div>
                              <Button
                                  variant="contained"
                                  sx={{ mt: 1, mr: 1 }}
                                  size="small"
                                  onClick={stepBack}
                                >
                                  Anterior
                                </Button>
                                <Button
                                  variant="contained"
                                  sx={{ mt: 1, mr: 1 }}
                                  size="small"
                                  onClick={stepForwardNoRegistered}
                                  disabled={registering}          
                                >
                                  {false ? 'Finish' : 'Siguiente'}
                                </Button>
                              </div>
                              <Typography variant="body2" sx={{fontWeight:'300',fontSize:'70%',mt:1.5,color:'grey'}}> 
                             Acepto las <span class="condiciones" style={{color:"blue"}}
                                onClick={verCondiciones}>condiciones</span> de servicio al hacer click en "siguiente".</Typography>
                          </Box>
                          
                    </>  
                  :Object.keys(customer).length>0 && haveAccount?  
                    <>    
                    <Stack sx={{mt:1}} direction="row" spacing={4} alignItems="center">
                    <Autocomplete 
                        id="grouped-demo"
                        options={[]}
                        value={nombreCliente}
                        sx={{ width: 425}}
                        renderInput={(params) => <TextField  {...params} sx={{mt:-1,mb:2 }} label="Nombre" variant="standard"/>}
                        />
                        
                    </Stack>
                    <Stack sx={{mt:1}} direction="row" spacing={4} alignItems="center">
                    <Autocomplete
                        id="grouped-demo"
                        onChange={(event, value) => setDireccionActiva(value)}
                        value={direccionActiva}
                        options={direcciones}
                        sx={{ width: 425,mb:2 }}
                        renderInput={(params) => <TextField error={erroValidacion===1} {...params} sx={{mt:-1,mb:2}} label="Dirección" variant="standard"/>}
                      />
                        
                    </Stack>
                    <Stack sx={{mt:1}} direction="row" spacing={4} alignItems="center">
                    <Autocomplete
                        id="grouped-demo"
                        value={telefonos}
                        options={[]}
                        sx={{ width: 425 }}
                        renderInput={(params) => <TextField {...params} sx={{mt:-1,mb:2}} label="Teléfono(s)" variant="standard"/>}
                        />
                        
                    </Stack>
                    <Stack sx={{mt:1}} direction="row" spacing={4} alignItems="center">

                    <Autocomplete
                        id="grouped-demo"
                        options={['Lunes','Martes','Miércoles','Jueves','Viernes']}
                        defaultValue={diaEnvio}
                        onChange={(event, value) => setDiaEnvio(value)}
                        error={erroValidacion===2}
                        sx={{ width: 150 }}
                        renderInput={(params) => <TextField error={erroValidacion===2} {...params} sx={{mt:-1,mb:2}} label="Día" variant="standard"/>}
                        />
                        <Autocomplete
                        id="grouped-demo"
                        options={['08:00-15:00']}
                        defaultValue={horaEnvio}
                        onChange={(event, value) => setHoraEnvio(value)}
                        error={erroValidacion===3}
                        sx={{ width: 240 }}
                        renderInput={(params) => <TextField error={erroValidacion===3} {...params} sx={{mt:-1,mb:2}} label="Hora" variant="standard" />}
                        />
                    </Stack>
                        <Button
                            variant="contained"
                            sx={{ mt: 1, mr: 1 }}
                            size="small"
                            onClick={stepBack}
                          >
                            Anterior
                          </Button>
                          <Button
                            variant="contained"
                            sx={{ mt: 1, mr: 1 }}
                            size="small"
                            onClick={stepForward}
                          >
                            {false ? 'Finish' : 'Siguiente'}
                          </Button>
                    </>
                  :
                  <>
                  </>
              }
            </StepContent>  
            </Step>
            <Step>
                <StepLabel>
                <Typography  variant='overline' sx={{fontSize:'100%'}}>Confirmación</Typography>
                </StepLabel>
                <StepContent>
                {
                  rows.filter(row=>row.id!=='TOTAL' && row.servicio===false).length===0?
                  <>
                  <Alert severity="warning">Elija las prendas en el primer paso</Alert>
                </>
                :Object.keys(customer)==0?
                    <>
                    <Alert severity="warning">Complete los datos</Alert>
                  </>
                :rows.filter(row=>row.id!=='TOTAL' && row.servicio===false).length>0?
                <>
                <Stack sx={{m:1}} direction="row" spacing={10}>
                      <DataGrid sx={{justifyContent:'center'}}
                        rows={rows}
                        columns={columns2}
                        columnVisibilityModel={{
                            // Hide columns status and traderName, the other columns will remain visible
                            id:false,
                            categoria: false,
                        }}
                        initialState={{
                        //pagination: {
                        // paginationModel: {
                        //   pageSize: 5,
                        // },
                        //},
                        }}
                        //pageSizeOptions={[5]}
                        //checkboxSelection=
                        disableRowSelectionOnClick
                        hideFooter
                        density='compact'
                        disableColumnFilter
                        disableColumnMenu
                        getCellClassName={getCellClassName}
                        />
                    </Stack>    
                <Card sx={{m:1,p:0}}>
                      <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor:'#ebe2d6',color:'black' }} aria-label="recipe">
                        {nombreCliente[0]}
                      </Avatar>
                    }
                      title={nombreCliente+'. '+telefonos}
                      subheader={direccionActiva+'. '+diaEnvio+'. '+horaEnvio}
                    />
                  </Card>
                  </>
                  :
                  <>
                  </>
                  } 
                  {
                    !finished?
                    <>
                    <Button
                        variant="contained"
                        sx={{ mt: 1, mr: 1 }}
                        size="small"
                        onClick={stepBack}
                      >
                        Anterior
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{ mt: 1, mr: 1 }}
                      size="medium"
                      onClick={finalize}
                      color="warning"
                    >
                      Realizar
                    </Button>
                    <Alert sx={{mt:2,opacity:0.5,mb:1}} severity="info">
                      <Typography variant='body2' sx={{color:'black'}}>
                      Recogida a domicilio: 2.5€. Entrega a domicilio: 2.5€
                      </Typography>
                      <Typography variant='body2' sx={{color:'black'}}>
                      Pedidos superiores a 40€.  Recogida y entrega a domicilio: gratis.
                      </Typography>
                      <Typography variant='body2' sx={{color:'black'}}>
                      Zonas de recogida. Consultar.
                      </Typography>
                    </Alert>

                      </>
                      :
                      <>
                    <Alert severity="success">Pedido realizado. En breve nos pondremos en contacto telefónico, para confirmar el pedido.</Alert>
                    </>
                  }
                </StepContent>
            </Step>
            </Stepper>
            </> 
        }
        </Paper>
        </>
    );

}


