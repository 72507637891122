import * as React from 'react'
import {useRef,useState,useEffect} from 'react';
import './App.css';
import { ThemeProvider, createTheme, rgbToHex } from '@mui/material/styles';
import Header from './componentesWeb/Header'
import Copyright from './componentesWeb/Footer';
import Container from '@mui/material/Container';
import Router from './Router'
import { green, purple } from '@mui/material/colors';
import CssBaseline from '@mui/material/CssBaseline';
import jwt from 'jwt-decode'

export const AuthContext = React.createContext(null);

function App() {
  //clone  informatica
  const theme = createTheme({
    /*
    typography: {
      fontFamily: [
        //' Lato','Raleway', "sans-serif"
        'ui-sans-serif', 'system-ui', '-apple-system', 'BlinkMacSystemFont',
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', 'Noto Sans','sans-serif'
      ].join(','),
      fontSize:18,      
    },
    */
    typography: {
      fontFamily: [
        //' Lato','Raleway', "sans-serif"
        'ui-sans-serif', 'system-ui', '-apple-system', 'BlinkMacSystemFont',
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', 'Noto Sans','sans-serif'
      ].join(','),
      fontSize:18,      
    },
    
    palette: {
      background: {
        //default: "#ebe2d6"
        default: "#ebe2d655"
      },
      primary: {
        main: '#ebe2d6',
      },
      secondary: {
        main: green[500],
      }},
      breakpoints: {
        values: {
          xs: 0,
          sm: 700,
          md: 800,
          lg: 1080,
          xl: 1536,
        },
      },
      /*
      typography: {
        fontFamily: [
          'Montserrat',
          'sans-serif',
          '"Helvetica Neue"',
          'Arial',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
      }
      */
  });


  const tokenAlmacenado=localStorage.getItem("token");
  const [token,setToken]=useState(tokenAlmacenado);  
  const [user, setUser] = useState(null); 

  React.useEffect(()=> {

    if (token)
        {
        const userId=jwt(token).id;
        const userRole=jwt(token).role || 'cliente';
        const userName=jwt(token).sub;
        const roles=[userRole];
        //console.log(jwt(token),userId,userRole,userName);
        setUser({id:userId,name:userName,roles:roles,permissions:[]});
        }
      }
      , [token])

  return (
    <>
    <AuthContext.Provider value={{token,setToken,user,setUser}}>
      <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router  user={user} setUser={setUser} token={token} setToken={setToken}/>
     {/* <Copyright sx={{ mt: 5 , mb:2}}/> */}
     
      </ThemeProvider>
    </AuthContext.Provider>
    </>
  );
}

export default App;
