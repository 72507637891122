import * as React from 'react'
import Typography from '@mui/material/Typography';
import { useParams,useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { DataGrid, selectedGridRowsCountSelector} from '@mui/x-data-grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import CardContent from '@mui/material/CardContent';
import Paper from '@mui/material/Paper';
import axios from "axios";
import jwt from 'jwt-decode';
import { TextField } from '@mui/material';

export default function DetallesPedido(props) {
    
	const { pid } = useParams();
  const {user,token,setToken}=props;
  const [pedido,setPedido]=React.useState([]);
  const [rows,setRows]=React.useState([]);
  const codigoPedido2=React.useRef()
  const [errorCodigo,setErrorCodigo]=React.useState(false);
  const [estado,setEstado]=React.useState('');
	const navigate = useNavigate()
	//console.log("id",pid);
  React.useEffect(()=> {
    //console.log('token',token);
    if (token!=='' && token)
    {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;  
    //console.log('userId',userId);
    const urlPedidosData=process.env.REACT_APP_API_URL+`admin/pedidos/obtener_pedido/${pid}`;
    axios
    .get(urlPedidosData)
    .then((response) => {
      console.log("Pedido data", response.data);
      setPedido(response.data[0]);
      //setDataQuery(response.data);
     })
     .catch((error) => {
      console.error("Pedido data error");
     });
    }
   }
   , [])

   React.useEffect(()=> {
    if (Object.keys(pedido).length>0) {
      console.log("prendas",pedido.prendas);
      /*
      prendas [{"id":0,"item":"Abrigo","precio":15.5,"cantidad":"1","preciounitario":15.5,"codProducto":2,"servicio":false}
      ,{"id":1,"item":"Urgente","precio":5,"cantidad":1,"preciounitario":5,"codProducto":3,"servicio":true}]
      */
     const prendas=JSON.parse(pedido.prendas).map(fila=> {
      //    {id:1,prenda:'pantalón',cantidad:'3',preciounitario:5,precio:15},
      return({'id':fila.id,'prenda':fila.item,'cantidad':fila.cantidad,'preciounitario':fila.preciounitario,'precio':fila.precio});
     })
  
    setRows(prendas);
    setEstado(pedido.estado);   
    }
   } , [pedido])
	
   function volver() {
		navigate('/gestion_pedidos');
	}

	function validar () {
    const codigo=codigoPedido2.current.value;
    console.log("codigo",token,codigo,user);
    if (codigo!=='')
    {
    setErrorCodigo(false);  
    const dataValidacion= {
      'asignado':user.name,
      'estado':'Validado',
      'codigo':codigo
    }
    const urlPedido=process.env.REACT_APP_API_URL+`admin/pedidos/actualizar_pedido/${pid}`;
    //axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;  
    axios
    .put(urlPedido,
      dataValidacion,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        },
      })
    .then((response) => {
      console.log("Pedido validado", response);
      setEstado('Validado');
      //setFinished(true);
     })
     .catch((error) => {
      console.error("Pedido validado error");
     });
    }
    else {
      setErrorCodigo(true);
    }

	}

	const columns = [
        {
        field: 'id',
            headerName: 'código',
            //width: '15%',
            flex:1,
            editable: false,
            sortable:true, 
			      hidden:true    
          },
          {
            field: 'prenda',
            headerName: 'Prenda',
            type: 'text',
            //width: 100,
            editable: false,
            sortable:true,     
            flex:2,
          },
        {
          field: 'precio',
          headerName: 'Precio',
		      type: 'number',
          //width: 150,
          flex:3,
          editable: false,
          sortable:true,    
          valueGetter: ({ row }) => {
            return row.preciounitario+'€';
          }, 
        },
        {
          field: 'cantidad',
          headerName: 'Cantidad',
          type: 'number',
          sortable:true,
          flex:2,
        },
		{
			field: 'total',
			headerName: 'Total',
			type: 'number',
			sortable:true,     
			//width: 100,
			flex:2,
			valueGetter: ({ row }) => {
			  return row.precio+'€';
			},
		  }
      ];

  /*    
      const rows = [
    {id:1,prenda:'pantalón',cantidad:'3',preciounitario:5,precio:15},
		{id:2,prenda:'pantalón',cantidad:'3',preciounitario:5,precio:15},
		{id:3,prenda:'pantalón',cantidad:'3',preciounitario:5,precio:15},
      ];
      
*/
	return (
		<>
    <Paper sx={{p:2,width:'100%'}} elevation={3} >
    {
      Object.keys(pedido).length>0?
      <>
		<Typography variant="h6"> Pedido {pid}.  <span style={{fontWeight:"300",fontSize:"90%"}}>{pedido.fecha.replace('T','  ').split('.')[0]}</span></Typography>
   
    <Card sx={{m:1,p:0}}>
        <CardHeader
      avatar={
        <Avatar sx={{ bgcolor:'#ebe2d6',color:'black' }} aria-label="recipe">
          S
        </Avatar>
      }
                //title="Juan Fernando Perez de Esteban"
                title={pedido.cliente}
                //subheader={pedido.direccion}
                //subheader="616000000 - 616000001"

              />

              <CardContent sx={{ml:7,mt:-4}}>
              <Typography variant="body2" color="#666666">Dirección: <span style={{fontWeight:"400",fontSize:"100%",color:"#212121"}}>{pedido.direccion} </span></Typography>
              <Typography variant="body2" color="#666666">Horario: <span style={{fontWeight:"400",fontSize:"100%",color:"#212121"}}>{pedido.horario}</span></Typography>  
              <Typography variant="body2" color="#666666"> Tel: <span style={{fontWeight:"400",fontSize:"100%",color:"#212121"}}>{Object.values(JSON.parse(pedido.telefonos.replaceAll('\'','"'))).join('-')}</span></Typography>
              <Typography variant="body2" color="#666666">Precio estimado: <span style={{fontWeight:"400",fontSize:"100%",color:"#212121"}}>{pedido.precio} €</span></Typography>
              {estado==='Validado'?
              <Typography variant="body2" color="green">{estado}</Typography>
              :
              <>
              <Typography variant="body2" color="red">{estado}</Typography>
              </>
              }
              </CardContent>
            </Card>
            <Typography variant='overline' sx={{fontSize:'100%'}}>Lista de prendas</Typography>

		<DataGrid sx={{ '& .validado': {backgroundColor:'#eeffee'},'& .pendiente': {fontWeight: 500,backgroundColor:'#ffeeee'}}}
        rows={rows}
        columns={columns}
        columnVisibilityModel={{
          id:false
        //
        }}
        initialState={{
        pagination: {
         paginationModel: {
           pageSize: 10,
         },
        },
        }}
        //pageSizeOptions={[5]}
        //checkboxSelection=
        disableRowSelectionOnClick
        //hideFooter
        //density='compact'
        //disableColumnFilter
        //disableColumnMenu
        //getCellClassName={getCellClassName}
        />
		
    <TextField error={errorCodigo} label="Número de Albarán" variant="standard" inputRef={codigoPedido2}/>
		<Button sx={{mt:1, mr:1}} onClick={validar} disabled={estado==='Validado'} color="success" variant="contained">Validar</Button>
		<Button sx={{mt:1, mr:1}} onClick={volver} variant="contained">Volver</Button>
    </>
    :
    <></>
      }
      </Paper>
		</>
)}