import * as React from 'react'
import { Link } from "react-router-dom";
import {AppBar,Typography,Grid,Box,Stack,Menu,MenuItem, IconButton, Button} from '@mui/material';
import logo from './../logo.jpg'
import MenuIcon from '@mui/icons-material/Menu';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useTranslation } from 'react-i18next';
import { withNamespaces } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';
import PublicIcon from '@mui/icons-material/Public';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import EuroIcon from '@mui/icons-material/Euro';
import GroupIcon from '@mui/icons-material/Group';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {AuthContext} from '../App';

export default function NavBarTrabajador(props) {
    
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    //const {user,setUser}=props;
    const {token,setToken,user,setUser} = React.useContext(AuthContext);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const [openModal, setOpenModal] = React.useState(false);
    const handleClickOpen = () => {
        setOpenModal(true);
      };
    
      const handleClickClose = () => {
        setOpenModal(false);
      };
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {i18n.changeLanguage(lng);}
    //let languageStored=sessionStorage.getItem('Language') || 'es';
    let languageStored='es';
    const [language,setLanguage]=React.useState(languageStored);    
    function handleChange() {
        if (language==="es") {
            setLanguage("en");
            
        }
        if (language==="en") {
            setLanguage("es");
        }
    }

    React.useEffect(()=> {
        changeLanguage(language);
        localStorage.setItem("Language",language);
    }
    , [language])

    function logout() {
        setOpenModal(false);
        setToken(null)
        setUser(null);
        localStorage.removeItem("token");
    }
   
return (
		<>
	    <AppBar position="static" sx={{ display: { xs: 'block', sm:'block', md: 'block', lg: 'block',xl:'block' }}}>
        <Grid  display="flex" alignItems="center" justifyContent="space-between" direction="row" 
        sx={{marginRight:10,marginLeft:10}}>
        <Stack sx={{mt:0.5,mb:0.5}} direction="row" justifyContent="center" alignItems="center" spacing={2}>
        <Box
            component="img"
            sx={{height: 130}}
            alt="Tintorería Sonia"
            src={logo}
            align="center"
            />
        <Stack direction="column" justifyContent="center" alignItems="center">    
        <Typography  color="success" sx={{marginLeft:2,fontSize:'160%'}} variant="overline"><span style={{fontWeight:"200"}}>La </span><span style={{fontWeight:"500"}}>Tintorería</span><span style={{fontWeight:"200"}}> de </span><span style={{fontWeight:"500"}}>Sonia</span></Typography>
        <Typography sx={{fontWeight:"200",mt:-2}} variant="body2">Tintorería tradicional de Sevilla</Typography>
        </Stack>
        </Stack>
        <Stack direction="row" alignItems="center">
        <HomeOutlinedIcon sx={{mr:1}}/>
        <Typography variant="overline">
            <Link   className="link-hover" to='/home'>Home</Link>
        </Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
        <ShoppingBasketOutlinedIcon sx={{mr:1}}/>
        <Typography variant="overline">
            <Link className="link-hover" to='/gestion_pedidos'>Pedidos</Link>
        </Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
        <EuroIcon sx={{mr:1}}/>
        <Typography variant="overline">
            <Link className="link-hover" to='/gestion_precios'>Precios</Link>
        </Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
        <GroupIcon sx={{mr:1}}/>
        <Typography variant="overline">
            <Link className="link-hover" to={false?'/gestion_usuarios':'#'}>Usuarios</Link>
        </Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
            <Button variant="standard" onClick={handleClickOpen} sx={{fontSize:'90%',color:'#4e4e4e'}}>Salir</Button>
            <Dialog
                        open={openModal}
                        onClose={handleClickClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                        {"¿Cerrar sesión?"}
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Si no cierras sesión, mantienes tus datos para el próximo pedido.
                            No tendrás que volver a introducir email y contraseña.
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button color="success"onClick={handleClickClose}>Mantener sesión</Button>
                        <Button color="error" onClick={logout} autoFocus>
                            Borrar datos
                        </Button>
                        </DialogActions>
                    </Dialog>
        </Stack> 
        </Grid>
       
        </AppBar>
{/*
        <AppBar position="static" sx={{ display: { xs: 'none', sm:'none',md: 'block', lg: 'none',xl:'none' }}}>
        <Grid  display="flex" alignItems="center" justifyContent="space-between" direction="row" 
        sx={{marginRight:3,marginLeft:3}}>
        <Stack direction="row" justifyContent="center" alignItems="center">
        <Box
            component="img"
            sx={{height: 100}}
            alt="Tintorería Sonia"
            src={logo}
            align="center"
            />
        <Stack direction="column" justifyContent="center" alignItems="center">   
         
        <Typography  sx={{marginLeft:2}} variant="h5">Tintorería Sonia</Typography>
        <Typography  sx={{marginLeft:2}} variant="body">Tintorería tradicional en Sevilla</Typography>
        </Stack>
        </Stack>
        <Typography variant="overline">
            <Link className="link-hover" to='/tintorerias'>Tintorerías</Link>
        </Typography>
        <Typography variant="overline">
            <Link className="link-hover" to='/servicios'>Servicios</Link>
        </Typography>
        <Typography variant="overline">
            <Link className="link-hover" to='/precios'>Precios</Link>
        </Typography>
        <Typography variant="overline">
            <Link className="link-hover" to='/pedidos'>Pedido online</Link>
        </Typography>
        <Typography variant="overline">
            <Link className="link-hover" to='/login'>Login</Link>
        </Typography>
        </Grid>
        </AppBar>
        
        <AppBar position="static" sx={{ display: { xs: 'none', sm:'block', md: 'none', lg: 'none',xl:'none' }}}>
            <Grid  display="flex" alignItems="center" justifyContent="space-around" direction="row">
            <Stack direction="row" justifyContent="center" alignItems="center">
                <Box
                    component="img"
                    sx={{height: 100}}
                    alt="Tintorería Sonia"
                    src={logo}
                    align="center"
                    />
                <Stack direction="column" justifyContent="center" alignItems="center">   
                    <Typography  sx={{marginLeft:2}} variant="h5">Tintorería Sonia</Typography>
                    <Typography  sx={{marginLeft:2}} variant="body">Tintorería tradicional en Sevilla</Typography>
                </Stack>
            </Stack>
            <Stack direction="column" justifyContent="center" alignItems="center">
            <Typography variant="overline">Menu</Typography>
            <IconButton color="primary" onClick={handleClick}
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}><MenuIcon sx={{color:'black'}}/></IconButton>
            <Menu
             id="basic-menu"
             anchorEl={anchorEl}
             open={open}
             onClose={handleClose}
             MenuListProps={{
               'aria-labelledby': 'basic-button',
             }}
            >
                <MenuItem onClick={handleClose}>
                <Typography variant="overline">
                <Link className="link-hover" to='/tintorerias'>Tintorerías</Link>
                </Typography>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                <Typography variant="overline">
                    <Link className="link-hover" to='/servicios'>Servicios</Link>
                </Typography>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                     <Typography variant="overline">
                     <Link className="link-hover" to='/precios'>Precios</Link>
                    </Typography>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Typography variant="overline">
                        <Link className="link-hover" to='/pedidos'>Pedido online</Link>
                    </Typography>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Typography variant="overline">
                        <Link className="link-hover" to='/login'>Login</Link>
                    </Typography>
                </MenuItem>

            </Menu>
            </Stack>
            </Grid>
        </AppBar>
        
        <AppBar position="static" sx={{ display: { xs: 'block', sm:'none', md: 'none', lg: 'none',xl:'none' }}}>
            <Grid  display="flex" alignItems="center" justifyContent="space-around" direction="row">
            <Stack direction="row" justifyContent="center" alignItems="center">
                <Box
                    component="img"
                    sx={{height: 100}}
                    alt="Tintorería Sonia"
                    src={logo}
                    align="center"
                    />
                <Stack direction="column" justifyContent="center" alignItems="center">   
                    <Typography  sx={{marginLeft:2}} variant="h6">Tintorería Sonia</Typography>
                    <Typography  sx={{marginLeft:2}} variant="body">Tintorería tradicional en Sevilla</Typography>
                </Stack>
            </Stack>
            <Stack direction="column" justifyContent="center" alignItems="center">
            <IconButton color="primary" onClick={handleClick}
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}><MenuIcon sx={{color:'black'}}/></IconButton>
            <Menu
             id="basic-menu"
             anchorEl={anchorEl}
             open={open}
             onClose={handleClose}
             MenuListProps={{
               'aria-labelledby': 'basic-button',
             }}
            >
                <MenuItem onClick={handleClose}>
                <Typography variant="overline">
                <Link className="link-hover" to='/tintorerias'>Tintorerías</Link>
                </Typography>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                <Typography variant="overline">
                    <Link className="link-hover" to='/servicios'>Servicios</Link>
                </Typography>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                     <Typography variant="overline">
                     <Link className="link-hover" to='/precios'>Precios</Link>
                    </Typography>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Typography variant="overline">
                        <Link className="link-hover" to='/pedidos'>Pedido online</Link>
                    </Typography>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Typography variant="overline">
                        <Link className="link-hover" to='/login'>Login</Link>
                    </Typography>
                </MenuItem>
            </Menu>
            </Stack>
            </Grid>
        </AppBar>
            */}
		</>
)}