import * as React from 'react'

export default function Burbujas(props) {
    
    return (
            <>
                  <div class="wrapper">
                    <div><span class="dot"></span></div>
                    <div><span class="dot"></span></div>
                    <div><span class="dot"></span></div>
                    <div><span class="dot"></span></div>
                    <div><span class="dot"></span></div>
                    <div><span class="dot"></span></div>
                    <div><span class="dot"></span></div>
                    <div><span class="dot"></span></div>
                    <div><span class="dot"></span></div>
                    <div><span class="dot"></span></div>
                    <div><span class="dot"></span></div>
                    <div><span class="dot"></span></div>
                    <div><span class="dot"></span></div>
                    <div><span class="dot"></span></div>
                    <div><span class="dot"></span></div>
        </div>
            </>
    )}