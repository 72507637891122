import { Typography } from '@mui/material';
import * as React from 'react'
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { DataGrid,  GridToolbar} from '@mui/x-data-grid';
import { Link } from "react-router-dom";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import axios from "axios";
import jwt from 'jwt-decode'
//import API_URL from '...env'

export default function GestionPedidos(props) {

    const {token,setToken}=props;
    let { params } = useParams();
    const { t, i18n } = useTranslation();
    const {user,setUser}=props;
    const [dataQuery,setDataQuery]=React.useState([]);
    const [rows,setRows]=React.useState([]);


    React.useEffect(()=> {
      //console.log('token',token);
      if (token!=='' && token)
      {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;  
      //console.log('userId',userId);
      const urlPedidosData=process.env.REACT_APP_API_URL+`admin/pedidos/obtener_pedidos/`;
      axios
      .get(urlPedidosData)
      .then((response) => {
        console.log("Pedidos data", response.data);
        setRows(response.data);
        //setDataQuery(response.data);
       })
       .catch((error) => {
        console.error("Pedidos data error");
       });
      }
     }
     , [token])
 
     /*
     React.useEffect(()=> {
      if (dataQuery.length>0) {parseDataQuery();}
     }
     , [dataQuery]);


    function  parseDataQuery() {

      const dataRows=dataQuery.map((item,index) => {
        const idPedido=item.pedido.id;
        const fechaPedido=item.pedido.fecha;
        const nombrePedido=item.cliente.first_name;
        const precioPedido=item.pedido.precio;
        const estadoPedido=item.pedido.estado;
        const usuarioPedido=item.pedido.asignado;
        //console.log(idPedido,fechaPedido,nombrePedido,precioPedido,estadoPedido,usuarioPedido);
        return({ id: idPedido, fecha:fechaPedido,cliente:nombrePedido,precio:precioPedido,estado:estadoPedido,usuario:usuarioPedido})
      })

      console.log('dataRows',dataRows);
      setRows(dataRows);
      // { id: 1, fecha:'11-09-23',cliente: 'Juan Fernando Perez de Esteban',precio:50,estado:'Pendiente',usuario:''},


    }
    */
    const columns = [
        {
        field: 'codigo',
            headerName: 'Núm. Albarán',
            //width: '15%',
            flex:1,
            editable: false,
            sortable:true,     
          },
          {
            field: 'fecha',
            headerName: 'Fecha',
            type: 'text',
            //width: 100,
            editable: false,
            sortable:true,     
            flex:1,
            valueGetter: ({ row }) => {
              return row.fecha;
            },
          },
        {
          field: 'cliente',
          headerName: 'Cliente',
          //width: 150,
          flex:2,
          editable: false,
          sortable:true,     
        },
        {
          field: 'precio',
          headerName: 'Precio',
          type: 'number',
          sortable:true,     
          //width: 100,
          flex:1,
          valueGetter: ({ row }) => {
            return row.precio+'€';
          },
        },
        {
          field: 'estado',
          headerName: 'Estado',
          type: 'text',
          //align:'center',
          //width: 100,
          flex:1,
          sortable:true,     
        },
        {
          field: 'usuario',
          headerName: 'Asignado',
          type: 'text',
          //align:'center',
          //width: 100,
          flex:1,
          sortable:true,     
        },
        {field: 'ver',headerName: '', 
            renderCell: (params) => (
          <Link to={'/gestion_pedidos/'+params.id}>Ver</Link>
        ),
        flex:1,
        filterable: false,
        align: 'center'
      }
      ];

      /*
      const rows = [
        { id: 1, fecha:'11-09-23',cliente: 'Juan Fernando Perez de Esteban',precio:50,estado:'Pendiente',usuario:''},
        { id: 2, cliente: 'Ropa', item: 'Pantalón',precio:10, cantidad: 1,preciounitario:10 },
        { id: 3, categoria: 'Ropa', item: 'Chaqueta',precio:25, cantidad: 1,preciounitario:5,estado:'Cancelado',usuario:'trabajador' },
        { id: 9, categoria: 'Ropa', item: 'Edredón sintético',precio:5, cantidad: 1,preciounitario:5 },
        { id: 10, fecha:'11-09-23',cliente: 'Juan Fernando Perez de Esteban',precio:50,estado:'Validado',usuario:'admin'},
        { id: 11, fecha:'11-09-23',cliente: 'Juan Fernando Perez de Esteban',precio:50,estado:'Pendiente'},
        { id: 12, fecha:'11-09-23',cliente: 'Juan Fernando Perez de Esteban',precio:50,estado:'Pendiente'},
      ];
      */



      
      const getCellClassName = ({ row, field }) => {
        //console.log(field,row)
        if (row.estado === 'Pendiente') { 
            return 'pendiente';
          }
          if (row.estado === 'Validado') { 
            return 'validado';
          }  
        return '';
      };
      
    return(
        <>
      <Paper sx={{p:2,width:'100%'}} elevation={3} >
        <Typography variant="h6">Pedidos</Typography>
        <DataGrid sx={{ '& .validado': {backgroundColor:'#eeffee'},'& .pendiente': {fontWeight: 500,backgroundColor:'#ffeeee'}}}
        rows={rows}
        columns={columns}
        columnVisibilityModel={{
        //
        }}
        initialState={{
        pagination: {
         paginationModel: {
           pageSize: 10,
         },
        },
        }}
        slots={{
            toolbar: GridToolbar,
            }}
        //pageSizeOptions={[5]}
        //checkboxSelection=
        disableRowSelectionOnClick
        //hideFooter
        //density='compact'
        //disableColumnFilter
        //disableColumnMenu
        getCellClassName={getCellClassName}
        />
        </Paper>
        </>
    );
}